import Vue from 'vue'

const state = {
  currentUser: null,
  siteSettings: {
    site_title: "Bookstab"
  },
  notifications: [],
  notify_dell_loading: false,
  last_notification_id: null,
  loadPage: false,
  loader: false,
  local_loading: false,
  global_loading: false,
  showHello: localStorage.getItem('show_hello') || true,
  banners:[],

}
const getters = {
  currentUser(state) {
    return state.currentUser;
  },
  siteSettings(state) {
    return state.siteSettings;
  },
  loadPage(state) {
    return state.loadPage;
  },
  loader(state) {
    return state.loader;
  },
  local_loading(state) {
    return state.local_loading;
  },
  global_loading(state) {
    return state.global_loading;
  },
  notifications(state) {
    return state.notifications;
  },
  notify_dell_loading(state) {
    return state.notify_dell_loading;
  },
  showHello(state) {
    if (!state.showHello || state.showHello == 'false') {
      state.showHello = false;
    }
    return state.showHello;
  },
  allEroFilters(state) {
    if (state.siteSettings && state.siteSettings.allEroFilters) {
      return state.siteSettings.allEroFilters;
    }
    return [];
  },
  banners(state) {
    return state.banners;
  }
}
const mutations = {
  SET_USER(state, user) {
    state.currentUser = user;

    if(user && user.libcats){
      this.commit('libraries/SET_LIBCATS', user.libcats, {
        root: true
      });
    }
  },
  ADD_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },
  ADD_NOTIFICATION(state, notification) {
    //Защита от дубликатов
    if (state.last_notification_id != notification.id) {
      state.last_notification_id = notification.id;
      state.notifications.unshift(notification);
    }

  },
  SET_NOTIFY_DELL_LOADING(state, loading) {
    state.notify_dell_loading = loading;
  },
  PAGE_IS_LOADING(state, data) {
    state.loadPage = data;
  },
  SET_LOADER(state, data) {
    state.loader = data;
  },
  GET_SETTINGS(state, settings) {
    state.siteSettings = settings;
  },
  SET_LOCAL_LOADING(state, data) {
    state.local_loading = data;
  },
  SET_GLOBAL_LOADING(state, data) {
    state.global_loading = data;
  },
  DELETE_NOTIFY(state, index) {
    state.notifications.splice(index, 1)
  },
  SET_CLOSE_HELLO(state) {
    state.showHello = false;
    localStorage.setItem('show_hello', false);
  },
  SET_USER_BALANCE(state, balance) {
    state.currentUser.balance = balance;
  },
  SET_BANNERS(state, banners){
    state.banners = banners;
  }
}

const actions = {
  setUser(context, data) {
    // context.dispatch('setUser');
    context.commit('SET_USER', data)
  },
  pageIsLoading(context, data) {
    context.commit('PAGE_IS_LOADING', data)
  },
  setLoader(context, data) {
    context.commit('SET_LOADER', data)
  },
  setLocalLoading(context, data) {
    context.commit('SET_LOCAL_LOADING', data)
  },
  setGlobalLoading(context, data) {
    context.commit('SET_GLOBAL_LOADING', data)
  },
  // async getSettings(context) {
  //   // console.log('token',context.rootState.auth.token);
  //   await Vue.axios.get('/get-settings')
  //     .then(response => {
  //       context.commit('GET_SETTINGS', response.data.settings);
  //     })
  //     .catch(() => {
  //       // console.log(error);
  //       // reject(error)
  //     })
  // },
  getSettings(context) {
    // console.log('token',context.rootState.auth.token);
    return new Promise((resolve, reject) => {
      Vue.axios.get('/get-settings')
        .then(response => {
          context.commit('GET_SETTINGS', response.data.settings);
          resolve(response)
        })
        .catch((error) => {
          // console.log(error);
          reject(error)
        })
    })
  },
  // async getSettingsUser(context) {
  //   console.log('отправляем запрос');
  //   await Vue.axios.get('/get-settings-user',{
  //     headers: {
  //       Authorization: 'Bearer ' + context.rootState.auth.token
  //     }})
  //     .then(response => {

  //       context.commit('GET_SETTINGS', response.data.settings);
  //       context.commit('SET_USER', response.data.user);
  //       context.commit('ADD_NOTIFICATIONS', response.data.user.notify);
  //       // return response.data.settings;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       reject(error);
  //     })
  // },
  getSettingsUser(context) {
    return new Promise((resolve, reject) => {
      Vue.axios.get('/get-settings-user', {
          headers: {
            Authorization: 'Bearer ' + context.rootState.auth.token
          }
        })
        .then(response => {

          context.commit('GET_SETTINGS', response.data.settings);
          context.commit('SET_USER', response.data.user);
          context.commit('ADD_NOTIFICATIONS', response.data.user.notify);
          // return response.data.settings;
          resolve(response)
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        })
    })
  },
  async dellNotify(context, data) {
    // console.log('token',context.rootState.auth.toke
    context.commit('SET_NOTIFY_DELL_LOADING', true);
    context.commit('DELETE_NOTIFY', data.index)
    await Vue.axios.delete("/dell-notify/" + data.notify_id)
      .then(() => {
        context.commit('SET_NOTIFY_DELL_LOADING', false);
      })
      .catch(() => {
        context.commit('SET_NOTIFY_DELL_LOADING', false);
      })

  },
  dellAllNotify(context) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete('/dell-all-notify')
        .then(response => {
          context.commit('ADD_NOTIFICATIONS', response.data.notifications);
          resolve(response)
        })
        .catch((error) => {
          reject(error);
        })
    })
  },
  async getNotifications(context) {
    // console.log('token',context.rootState.auth.toke
    await Vue.axios.get("/get-my-notifications")
      .then((response) => {
        // context.commit('SET_USER', response.data.user);
        context.commit('ADD_NOTIFICATIONS', response.data.notifications);
      })
      .catch(() => {})

  }



}

export default {
  // namespaced: true,
  state,
  mutations,
  getters,
  actions
}