<template>
  <v-app>
    <v-app-bar
      v-if="this.$route.meta.group != 'Reader'"
      app
      color="primary"
      dark
      clipped-left
    >
      <v-app-bar-nav-icon
        @click.stop="showLeftSidebar = !showLeftSidebar"
      ></v-app-bar-nav-icon>

      <v-toolbar-title v-if="siteSettings && !isMobile">
        <router-link :to="{ name: 'Home' }" exact>{{
          siteSettings.site_title
        }}</router-link>
      </v-toolbar-title>

      <v-btn icon exact v-if="isMobile" :to="{ name: 'Home' }">
        <v-icon tile>$vuetify.icons.home</v-icon>
      </v-btn>

      <v-btn
        icon
        exact
        v-if="isMobile"
        :to="{ name: 'AccountLibrary' }"
        class="top-menu-item"
        ><v-icon>$vuetify.icons.bookshelf</v-icon>
      </v-btn>
      <router-link
        v-if="!isMobile"
        :to="{ name: 'AccountLibrary' }"
        class="top-menu-item top-bar-library"
        >Моя библиотека</router-link
      >
      <router-link
        v-if="!isMobile && currentUser && currentUser.vip"
        :to="{
        name: 'CategoryShow',
        params: { slug: 'all' },
        query: { sort: 'loyalty' },
      }"
        class="top-menu-item ml-4"
        >VIP-Каталог</router-link
      >

      <v-btn
        icon
        exact
        v-if="isMobile"
        :to="{
          name: 'CategoryShow',
          params: { slug: 'all' },
          query: { sort: 'discount' },
        }"
        class="top-menu-item"
        ><v-icon>$vuetify.icons.sale</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-slide-y-transition>
        <v-text-field
          v-model="searchString"
          v-show="showTopSearch && !isMobile"
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="$vuetify.icons.close"
          append-icon="$vuetify.icons.magnify"
          @click:append="goSearch"
          @click:prepend-inner="closeSearch"
          placeholder="Поиск"
          class="mr-4 top-search"
          ref="topSearch"
        />
      </v-slide-y-transition>

      <v-btn
        class="mr-1"
        v-show="!showTopSearch"
        icon
        @click.stop="openTopSearch"
      >
        <v-icon>$vuetify.icons.magnify</v-icon>
      </v-btn>

      <div v-if="currentUser">
        <v-btn class="mx-1" icon exact :to="{ name: 'AccountNotifications' }">
          <v-badge
            :content="count_notify"
            :value="count_notify"
            color="green"
            overlap
          >
            <v-icon>$vuetify.icons.bell</v-icon>
          </v-badge>
        </v-btn>

        <!-- <v-avatar class="ml-2" size="40" v-if="currentUser">
            <img id="head-avatar" style="cursor:pointer" :src="currentUser.avatar_url" />
        </v-avatar>-->
        <v-menu left bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-avatar class="ml-1" :size="isMobile ? 40 : 48" v-on="on">
              <img
                id="head-avatar"
                style="cursor: pointer"
                :src="currentUser.avatar_url"
              />
              <div class="vip-bage" v-if="currentUser.vip">vip</div>
            </v-avatar>
          </template>
          <v-list>
            <v-list-item
              class="user-name-menu"
              :to="{ name: 'UserShow', params: { userName: currentUser.name } }"
            >
              <v-list-item-title>{{
                currentUser.public_name
              }}</v-list-item-title>
            </v-list-item>

            <v-list-item 
            two-line
              v-if="currentUser.vip"
              class="user-name-menu"
              :to="{
                name: 'CategoryShow',
                params: { slug: 'all' },
                query: { sort: 'loyalty' },
              }"
            >
            <v-list-item-content>
              <v-list-item-title class="success--text"
                >Книги со скидкой</v-list-item-title
              >
               <v-list-item-subtitle>Закончится {{ currentUser.vip_left }}</v-list-item-subtitle>
               </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="isAuthor"
              class="com-cab-menu"
              :to="{ name: 'Commerce' }"
            >
              <v-list-item-title class="success--text"
                >Коммерческий кабинет</v-list-item-title
              >
            </v-list-item>
            <!-- <v-list-item :to="{name: 'UserShow', params: {userId: currentUser.id}}">
              <v-list-item-title>Моя страница</v-list-item-title>
            </v-list-item>-->
            <v-list-item :to="{ name: 'AccountLibrary' }">
              <v-list-item-title>Моя библиотека</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="isAuthor" :to="{ name: 'CabinetBooks' }">
              <v-list-item-title>Мои романы</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'CabinetBookAdd' }">
              <v-list-item-title class="primary--text"
                >Добавить книгу</v-list-item-title
              >
            </v-list-item>

            <v-list-item :to="{ name: 'AccountPurchases' }">
              <v-list-item-title>Купленные книги</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="isAuthor" :to="{ name: 'CabinetBlogs' }">
              <v-list-item-title>Мои блоги</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="isAuthor" :to="{ name: 'PartnerIndex' }">
              <v-list-item-title>Партнерская программа</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isAuthor" :to="{ name: 'MyBooksLoyalty' }">
              <v-list-item-title>Программа лояльности </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="isAuthor"
              :to="{ name: 'CabinetStatisticsIndex' }"
            >
              <v-list-item-title>Статистика прочтений</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="isAuthor" :to="{ name: 'CabinetStatisticsDay' }">
              <v-list-item-title>Статистика продаж</v-list-item-title>
            </v-list-item>

            <v-list-item :to="{ name: 'CabinetSettings' }">
              <v-list-item-title>Настройки профиля</v-list-item-title>
            </v-list-item>

            <!-- <v-list-item :to="{ name: 'CabinetIndex' }">
              <v-list-item-title>Кабинет</v-list-item-title>
            </v-list-item> -->

            <!-- <v-list-item :to="{name: 'CabinetSettings'}">
              <v-list-item-title>Настройки</v-list-item-title>
            </v-list-item>-->

            <v-list-item :to="{ name: 'Logout' }">
              <v-list-item-title>Выйти</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-menu v-if="!currentUser" left bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">Войти</v-btn>
        </template>

        <v-list>
          <v-list-item :to="{ name: 'Login', query: loginQuery }">
            <v-list-item-title>Войти</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'Register', query: loginQuery }">
            <v-list-item-title>Регистрация</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <template v-if="showTopSearch && isMobile" v-slot:extension>
        <v-slide-y-transition>
          <v-text-field
            v-model="searchString"
            v-show="showTopSearch && isMobile"
            flat
            dense
            solo-inverted
            hide-details
            prepend-inner-icon="$vuetify.icons.close"
            append-icon="$vuetify.icons.magnify"
            @click:append="goSearch"
            @click:prepend-inner="closeSearch"
            placeholder="Поиск"
            ref="topSearchMobile"
          />
        </v-slide-y-transition>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-if="this.$route.meta.group != 'Reader'"
      class="grey lighten-5"
      v-model="showLeftSidebar"
      app
      clipped
    >
      <v-list-item-group color="primary">
        <v-list-item :to="{ name: 'Home' }" exact class="mt-4" link>
          <v-list-item-icon>
            <v-icon color="grey darken-1">$vuetify.icons.home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>На главную</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <category-tree
        v-if="
          this.$route.meta.group != 'Cabinet' &&
          this.$route.meta.group != 'Commerce'
        "
      ></category-tree>
      <cabinet-list v-else></cabinet-list>
      <site-left-menu></site-left-menu>
    </v-navigation-drawer>
    <v-main>
      <page-loader></page-loader>
      <!-- <v-progress-linear :active="loading" absolute indeterminate></v-progress-linear> -->
      <!-- <router-view name="Home"></router-view> -->
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <app-footer v-if="this.$route.meta.group != 'Reader'"></app-footer>
    <snackbar-error></snackbar-error>
  </v-app>
</template>

<script>
// import { mdiMagnify, mdiBell} from '@mdi/js'
import CategoryTree from "@/components/CategoryTree";
import CabinetList from "@/components/cabinet/CabinetList";
import SiteLeftMenu from "@/components/SiteLeftMenu";
import Echo from "laravel-echo";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    CategoryTree,
    CabinetList,
    SiteLeftMenu,
  },
  data: () => ({
    showLeftSidebar: true,
    showTopSearch: false,
    count_notify: 0,
    searchString: "",
    // mdiMagnifySVG: mdiMagnify,
    // mdiBell: mdiBell,
  }),
  methods: {
    notCount() {
      if (this.notifications) {
        let count_arr = this.notifications.filter((val) => {
          return val.read_at == "null" || !val.read_at;
        });
        this.count_notify = count_arr.length;
      }
    },
    openTopSearch() {
      this.showTopSearch = !this.showTopSearch;
      if (this.isMobile) {
        this.$nextTick(() => this.$refs.topSearchMobile.focus());
      } else {
        // this.$nextTick(this.$refs.topSearch.focus());
        this.$nextTick(() => this.$refs.topSearch.focus());
      }
    },
    closeSearch() {
      this.showTopSearch = false;
      this.searchString = "";
    },
    goSearch() {
      let string = this.searchString.trim();
      if (string) {
        // console.log('search',this.searchString);
        this.$router.push({
          name: "Search",
          params: { search: string },
        });
        this.showTopSearch = false;
      }
    },
  },
  computed: {
    ...mapGetters(["loggedIn", "siteSettings", "currentUser", "notifications"]),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    token() {
      return this.$store.getters.token;
    },
    loginQuery() {
      if (
        this.$route.name != "Login" &&
        this.$route.name != "Register" &&
        this.$route.name != "SocialDopRegister"
      ) {
        return { return_url: this.$route.fullPath };
      }
      return {};
    },
    isAuthor() {
      if (this.currentUser && this.currentUser.author) {
        return true;
      }
      return false;
    },
  },
  watch: {
    notifications() {
      // console.log(this.notifications.length);
      this.notCount();
    },

    currentUser() {
      if (this.currentUser) {
        let show_ssl = false;
        if (process.env.VUE_APP_SHOW_SS == "true") {
          show_ssl = true;
        }
        window.Echo = new Echo({
          broadcaster: "pusher",
          key: 123,
          // wsHost: window.location.hostname,
          // host: process.env.VUE_APP_WSS_URL + ':6001',
          wsHost: process.env.VUE_APP_WSS_URL,
          wsPort: 6001,
          wssPort: 6001,
          authEndpoint: process.env.VUE_APP_API_URL + "/broadcasting/auth",
          auth: {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
          // authTransport: 'jsonp',
          httpHost: process.env.VUE_APP_WSS_URL,
          httpPath: "",
          disableStats: true,
          encrypted: show_ssl,
          useTLS: show_ssl,
          forceTLS: show_ssl,
          enabledTransports: ["ws", "wss"], // <- added this param,
        });
        // window.Echo.connector.pusher.config.auth.headers['Authorization'] = 'Bearer ' + this.token;
        //  console.log(window.Echo.connector.pusher.config);

        // window.Echo.private('App.User.'+this.currentUser.id)
        // .listen('PrivateGlobal',(e) => {
        //     console.log(e);
        // });

        window.Echo.private(
          "App.Models.User." + this.currentUser.id
        ).notification((notification) => {
          // console.log(notification);
          this.$store.commit("ADD_NOTIFICATION", notification);
        });
      }
    },
    // siteSettings(){
    //   console.log(this.siteSettings.site_title);
    //   this.siteName = this.siteSettings.site_title;
    // }
  },
  created: function () {
    //Цепляем токен к запросам axios
    // window.Echo.auth.headers.authorization = "Bearer " + this.token;
    // console.log($vuetify.icons.magnify);
    if (this.token) {
      this.axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.token;
    }
    let app = this;
    this.axios.interceptors.response.use(
      function (response) {
        if (response.data) {
          app.$eventBus.$emit("show-modal-error", [
            true,
            response.data,
            "success",
          ]);
        }
        // Do something with response data
        return response;
        // return Promise.reject(response);
      },
      function (error) {
        // Do something with response error
        // console.log(error.response.data);
        // console.log(error);
        // console.log(error.response.headers);
        if (
          error.response &&
          error.response.status == 401 &&
          error.response.data.message == "Unauthenticated."
        ) {
          // console.log("Выходим, токен просрочен");
          error.response.data.message = "Токен просрочен, авторизуйтесь снова";
          app.$store.dispatch("destroyToken");
          app.$router.push({ name: "Login" });
        }

        if (error.response && error.response.status == 404) {
          app.$router.push({
            name: "NotFound",
            // params: {},
            // query: {}
          });
        } else if (error.response && error.response.data) {
          app.$eventBus.$emit("show-modal-error", [
            true,
            error.response.data,
            "error",
          ]);
        }

        return Promise.reject(error);
      }
    );

    //Чтобы не грузил колбек от соцсетей
    if (this.$route.name != "socialCallback") {
      if (this.loggedIn) {
        this.$store.dispatch("getSettingsUser").catch(() => {
          this.$store.dispatch("destroyToken");
          this.$store.dispatch("getSettings");
        });
      } else {
        this.$store.dispatch("getSettings");
      }
    }

    //  this.axios.interceptors.response.use(undefined, function (err) {
    //   return new Promise(function (resolve, reject) {
    //     if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
    //       this.$store.dispatch("logout")
    //     }
    //     throw err;
    //   });
    // });
    this.notCount();

    if (this.$vuetify.breakpoint.mdAndDown) {
      this.showLeftSidebar = false;
    }
  },
  mounted() {},
};
</script>
