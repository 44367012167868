import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import pages from './modules/pages'
import settings from './modules/settings'
import books from './modules/books'
import reader from './modules/reader'
import comments from './modules/comments'
import reports from './modules/reports'
import supports from './modules/supports'
import user from './modules/user'
import account from './modules/account'
import cabinet_series from './modules/cabinet/cabinet_series'
import cabinet_books from './modules/cabinet/cabinet_books'
import cabinet_promo from './modules/cabinet/cabinet_promo'
import cabinet_chapters from './modules/cabinet/cabinet_chapters'
import cabinet_blogs from './modules/cabinet/cabinet_blogs'
import cabinet_balance from './modules/cabinet/cabinet_balance'
import cabinet_transactions from './modules/cabinet/cabinet_transactions'
import cabinet_statistics from './modules/cabinet/cabinet_statistics'
import cabinet_payouts from './modules/cabinet/cabinet_payouts'
import cabinet_partner from './modules/cabinet/cabinet_partner'
import info from './modules/info/info'
import contest from './modules/contest/contest'
import blog from './modules/blog/blog'
import likes from './modules/likes'
import libraries from './modules/libraries'
import documents from './modules/commerce/documents'
import requisites from './modules/commerce/requisites'

Vue.use(Vuex);

export default new Vuex.Store({

  modules: {
    auth,
    settings,
    pages,
    books,
    comments,
    reports,
    supports,
    user,
    account,
    reader,
    cabinet_series,
    cabinet_books,
    cabinet_promo,
    cabinet_chapters,
    cabinet_blogs,
    cabinet_balance,
    cabinet_transactions,
    cabinet_statistics,
    cabinet_payouts,
    cabinet_partner,
    info,
    contest,
    blog,
    likes,
    libraries,
    documents,
    requisites,
  }


  // state: {


  // },
  // getters:{

  // },
  // mutations: {


  // },
  // actions: {


  // },
  // modules: {
  // }
})