import Vue from 'vue'
const state = {

  likes: [],
  likesIds: [],
  likesCount: 0,

}
const getters = {

  likes(state) {
    return state.likes;
  },
  likesIds(state) {
    return state.likesIds;
  },
  likesCount(state) {
    return state.likesCount;
  }

}
const mutations = {

  ADD_LIKES(state, likes) {
    state.likes = likes;
  },
  ADD_LIKES_IDS(state, likesIds) {
    state.likesIds = likesIds;
  },
  ADD_LIKES_COUNT(state, count) {
    state.likesCount = count;
  },

}



const actions = {

  addLike(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/add-like/" + data.tableId + "/" + data.tableType)
        .then(response => {
          context.commit('ADD_LIKES_COUNT', response.data.likes_ids.length)
          context.commit('ADD_LIKES_IDS', response.data.likes_ids)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },



}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}