import Vue from 'vue'
const state = {
  blogs: [],
  blog: null,
}
const getters = {
  blog(state) {
    return state.blog;
  },
  blogs(state) {
    return state.blogs;
  },

}
const mutations = {
  SET_BLOGS(state, blogs) {
    state.blogs = blogs;
  },
  SET_BLOG(state, blog) {
    state.blog = blog;
  },

}



const actions = {


  getBlogs(context) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('SET_BLOGS', [])
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get("/blogs-my")
        .then(response => {

          context.commit('SET_BLOGS', response.data.blogs)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  getEditBlog(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('SET_BLOG', null)
    return new Promise((resolve, reject) => {
      Vue.axios.get("/blog/" + data.blog_id + "/edit")
        .then(response => {
          context.commit('SET_BLOG', response.data.blog)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })

  },
  addBlog(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post(
          "/blog", {
            title: data.title,
            body: data.body,
            status: data.status,
            excerpt: data.excerpt,
            text_count: data.text_count,
          })
        .then(response => {
          context.commit('SET_BLOG', response.data.blog)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateBlog(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.put("/blog/" + data.blog_id, {
          title: data.title,
          excerpt: data.excerpt,
          body: data.body,
          status: data.status,
          text_count: data.text_count,
        })
        .then(response => {
          context.commit('SET_BLOG', response.data.blog)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dellBlog(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete("/blog/" + data.deleteData[0])
        .then(response => {
          context.commit('SET_BLOGS', response.data.blogs)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}