import Vue from 'vue'
const state = {
  promoCodes: [],
  discounts: [],
  promoDays: 0,

}
const getters = {
  promoCodes(state) {
    return state.promoCodes;
  },
  discounts(state) {
    return state.discounts;
  },
  promoDays(state) {
    return state.promoDays;
  },

}
const mutations = {

  SET_PROMO(state, promoCodes) {
    state.promoCodes = promoCodes;
  },
  SET_DISCOUNTS(state, discounts) {
    state.discounts = discounts;
  },
  SET_PROMO_DAYS(state, days) {
    state.promoDays = days;
  },



}



const actions = {

  generatePromo(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/promo/" + data.book_id + "/generate")
        .then(response => {

          context.commit('SET_PROMO', response.data.promoCodes)
          context.commit('SET_PROMO_DAYS', response.data.promoDays)

          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  sendPromo(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/promo/" + data.book_id + "/send", {
          promoCode: data.promoCode
        })
        .then(response => {

          context.commit('SET_PROMO', response.data.promoCodes)
          context.commit('SET_PROMO_DAYS', response.data.promoDays)

          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  sendDiscount(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/discount/" + data.book_id + "/set", {
          date: data.dateToPublish,
          percent: data.percent,
        })
        .then(response => {
          context.commit('SET_DISCOUNTS', response.data.discounts)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dellDiscount(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete("/discount/" + data.id)
        .then(response => {
          context.commit('SET_DISCOUNTS', response.data.discounts)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}