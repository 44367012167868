<template>
  <div>
    <v-list dense>
      <v-list-item-group color="primary">
        <v-list-item
          v-if="currentUser"
          :to="{
            name: 'AccountPurchases',
          }"
          class="mt-1"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Купленные книги</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item
            :to="{
              name: 'ContestAll',
            }"
            class="mt-1"
            link
          >
            <v-list-item-content>
              <v-list-item-title>Конкурсы</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        <v-list-item
          :to="{
            name: 'CategoryInfoShow',
            params: { slug: 'news' },
          }"
          class="mt-1"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Новости</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
         
          :to="{
            name: 'CategoryInfoShow',
            params: { slug: 'spravka-avtoram' },
          }"
          class="mt-1"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Справка авторам</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
         
          :to="{
            name: 'CategoryInfoShow',
            params: { slug: 'spravka-chitatelyam' },
          }"
          class="mt-1"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Справка читателям</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          
          :to="{
            name: 'Page',
            params: { slug: 'usloviya-prodazhi-knig' },
          }"
          class="mt-1"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Условия продажи книг</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


         <v-list-item
          v-if="isAuthor"
          :to="{ name: 'PartnerIndex' }"
          class="mt-1"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Партнерская программа</v-list-item-title>
          </v-list-item-content>
        </v-list-item>





        <v-list-item
          :to="{
            name: 'Page',
            params: { slug: 'polzovatelskoe-soglashenie' },
          }"
          class="mt-1"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Пользовательское соглашение</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ name: 'Page', params: { slug: 'privacy-policy' } }"
          class="mt-1"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Политика конфиденциальности</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ name: 'Page', params: { slug: 'dogovor-oferta' } }"
          class="mt-1"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Договор-оферта</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ name: 'Page', params: { slug: 'pravila-sajta' } }"
          class="mt-1"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Правила сайта</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ name: 'Page', params: { slug: 'kontaktnaya-informaciya' } }"
          class="mt-1"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Контактная информация</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ name: 'Page', params: { slug: 'hello' } }"
          class="mt-1"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Помощь</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ name: 'Page', params: { slug: 'pravoobradatelyam' } }"
          class="mt-1"
          link
        >
          <v-list-item-content>
            <v-list-item-title>Правообладателям</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-list dense rounded class="mb-4">
      <v-list-item-group color="primary">
        <v-list-item color="primary" :to="{ name: 'Support' }" class="mt-1">
          <v-list-item-action>
            <v-icon color="primary">$vuetify.icons.help_circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="primary--text"
              >Поддержка</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item color="primary" href="https://vk.com/bookstab" class="mt-1">
          <v-list-item-action>
            <v-icon color="primary">$vuetify.icons.vk</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="primary--text"
              >Vkontakte</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item color="primary" href="https://t.me/bookstab" class="mt-1">
          <v-list-item-action>
            <v-icon color="primary">$vuetify.icons.tg</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="primary--text"
              >Telegram</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["currentUser"]),
    isAuthor() {
      if (this.currentUser && this.currentUser.author) {
        return true;
      }
      return false;
    },
  },
};
</script>