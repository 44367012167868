import Vue from 'vue'
const state = {
  comments: [],
  comments_page: 1,
  comments_pages: 1,
  comments_sort: 'new',
  comment_type: {
    'contest': {
      'id_name': 'contest_id',
      'path': 'contest-comment',
      'type': 'contest',
    },
    'comments': {
      'id_name': 'book_id',
      'path': 'comment',
      'type': 'book',
    }
  }
}
const getters = {
  comments(state) {
    return state.comments;
  },
  comments_page(state) {
    return state.comments_page;
  },
  comments_pages(state) {
    return state.comments_pages;
  },
  comments_sort(state) {
    return state.comments_sort;
  },

}
const mutations = {
  SET_COMMENTS(state, comments) {
    state.comments = comments;
  },
  SET_PAGE(state, comments_page) {
    state.comments_page = comments_page;
  },
  SET_PAGES(state, comments_pages) {
    state.comments_pages = comments_pages;
  },
  SET_SORT(state, comment_sort) {
    state.comments_sort = comment_sort;
  },
  // ADD_COMMENT(state, payload) {
  //   console.log(payload.type)
  //   // state.books = books;
  //   // findComments(state, payload.comment, payload.type);
  //   // state.comments.unshift(comment);
  // },

  // DELL_COMMENT(state, comment) {
  //   // console.log(books)
  //   // state.book = book;
  // },
  // EDIT_COMMENT(state, comment) {
  //   // console.log(books)
  //   // state.book = book;
  // },
}



const actions = {
  addComment(context, data) {
    // let path = context.state.comment_type[data.namespace].path;
    // let id_name = context.state.comment_type[data.namespace].id_name;
    //  let commentable_type = context.state.comment_type[data.namespace].type;
    return new Promise((resolve, reject) => {
      Vue.axios.post("/comment?page=" +
          context.state.comments_page +
          "&sorting=" +
          context.state.comments_sort, {
            'body': data.body,
            'parent_id': data.parent_id,
            'commentable_id': data.commentable_id,
            'commentable_type':data.commentable_type,
          })
        .then(response => {
          if (data.namespace == 'comments') {
            context.commit('ADD_COMMENTS_PLUS', null, {
              root: true
            });
          }
          context.commit('SET_COMMENTS', response.data.comments.data)
          context.commit('SET_PAGES', response.data.comments.last_page)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  editComment(context, data) {
    // let path = context.state.comment_type[data.namespace].path;
    return new Promise((resolve, reject) => {
      Vue.axios.put("/comment/" + data.comment_id +
          "?page=" +
          context.state.comments_page +
          "&sorting=" +
          context.state.comments_sort, {
            body: data.body,
          })
        .then(response => {
          context.commit('SET_COMMENTS', response.data.comments.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dellComment(context, data) {
    // let path = context.state.comment_type[data.namespace].path;
    return new Promise((resolve, reject) => {
      Vue.axios.delete("/comment/" + data.comment_id +
          "?page=" +
          context.state.comments_page +
          "&sorting=" +
          context.state.comments_sort)
        .then(response => {
          context.commit('SET_COMMENTS', response.data.comments.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // getComments(context, data) {
  //   console.log(data);
  //   context.commit('SET_PAGE', data.page)
  //   context.commit('SET_SORT', data.sort)

  //   return new Promise((resolve, reject) => {
  //     Vue.axios.get("/get-book-comments/" +
  //         data.book_id +
  //         "?page=" +
  //         context.state.page +
  //         "&sorting=" +
  //         context.state.sort)
  //       .then(response => {

  //         context.commit('SET_COMMENTS', response.data.comments.data)
  //         resolve(response)
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })


  // },

}


// function findComments(state, new_comment, type = "new") {
//   for (let i = 0; i < state.comments.length; i++) {
//     let comment = state.comments[i];
//     // console.log("Ищем" + type, comment.id);

//     if (type == "delete") {
//       if (
//         comment.id == new_comment.tree_id &&
//         comment.last_children.id == new_comment.id
//       ) {
//         comment.last_children.body = new_comment.body;
//         comment.last_children.deleted_at = new_comment.deleted_at;
//       }

//       if (comment.id == new_comment.id) {
//         //console.log("Ура мы его нашли", comment.id);
//         //console.log("Удаляем в дереве");
//         comment.body = new_comment.body;
//         comment.deleted_at = new_comment.deleted_at;

//         break;
//       }
//     } else if (type == "new") {
//       if (new_comment.tree_id) {
//         if (comment.id == new_comment.tree_id) {
//           // console.log("Нашли главу дерева, меняем lsat_child", comment.id);

//           comment.last_children = new_comment;
//           // console.log("last_children", comment.last_children);
//           comment.children_count
//             ? comment.children_count++
//             : (comment.children_count = 1);
//         }

//         if (comment.id == new_comment.parent_id) {
//           // console.log("Ура мы его нашли", comment.id);
//           if (comment.children) {
//             comment.children.push(new_comment);
//             //console.log("ПУШИМ", comment.id);
//           } else {
//             //почему то этот момент не отрабатывает
//             comment.children = [new_comment];
//             // console.log("создаем новый массив", comment.id);
//           }

//           break;
//         }
//       } else {
//         //Вставляем коммент в самый верх
//         state.comments.push(new_comment);
//       }
//     } else if (type == "edit") {
//       if (
//         comment.id == new_comment.tree_id &&
//         comment.last_children.id == new_comment.id
//       ) {
//         comment.last_children.body = new_comment.body;
//       }

//       if (comment.id == new_comment.id) {
//         comment.body = new_comment.body;

//         break;
//       }
//     }

//     if (comment.children) {
//       findComments(comment.children, new_comment, type);
//     }
//   }
// }

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}