import Vue from 'vue'
const state = {
  chapter: null,
  book: null,
  chapters: [],
  chapters_public: [],


}
const getters = {
  chapter(state) {
    return state.chapter;
  },
  book(state) {
    return state.book;
  },
  chapters(state) {
    return state.chapters;
  },
  chapters_public(state) {
    return state.chapters_public;
  },

}
const mutations = {
  GET_CHAPTERS(state, chapters) {
    state.chapters = chapters;

    let chapters_public = chapters.filter(function(chapters) {
      return chapters.status == 1;
    });

    state.chapters_public = chapters_public;
  },
  GET_CHAPTER(state, chapter) {
    state.chapter = chapter;
  },
  GET_BOOK(state, book) {
    state.book = book;
  },


}



const actions = {
  getEditChapter(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('GET_CHAPTER', null)
    context.commit('GET_BOOK', null)
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get("/chapter/" + data.chapter_id + "/edit")
        .then(response => {

          context.commit('GET_CHAPTER', response.data.chapter)
          context.commit('GET_BOOK', response.data.chapter.book)

          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  getCreateChapter(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get("/chapter/" + data.book_id + "/create")
        .then(response => {
          context.commit('GET_BOOK', response.data.book)
          // context.commit('GET_CHAPTER', response.data.chapter)

          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  addChapter(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post(
          "/chapter", {
            title: data.title,
            body: data.body,
            // status: data.status,
            publishType: data.publishType,
            dateToPublish: data.dateToPublish,
            bookStatus: data.bookStatus,
            bookFinish: data.bookFinish,
            book_id: data.book_id,
            text_count: data.text_count,
          })
        .then(response => {
          context.commit('GET_CHAPTER', response.data.chapter)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateChapter(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.put("/chapter/" + data.chapter_id, {
          title: data.title,
          body: data.body,
          // status: data.status,
          publishType: data.publishType,
          dateToPublish: data.dateToPublish,
          timeToPublish: data.timeToPublish,
          bookStatus: data.bookStatus,
          bookFinish: data.bookFinish,
          text_count: data.text_count,
          book_id: data.book_id,
        })
        .then(response => {
          context.commit('GET_CHAPTER', response.data.chapter)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dellChapter(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete("/chapter/" + data.deleteData[0])
        .then(response => {
          context.commit('GET_CHAPTERS', response.data.chapters)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dellAllChapters(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete('/dell-all-chapters/'+ data.book_id)
        .then(response => {
          context.commit('GET_CHAPTERS', [])
          resolve(response)
        })
        .catch((error) => {
          reject(error);
        })
    })
  },
  uploadChapterFile(context, formData) {
    // context.dispatch('pageIsLoading', true, {
    //   root: true
    // })
    return new Promise((resolve, reject) => {
      // console.log(data);
      Vue.axios.post("/chapter-upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          // context.dispatch('pageIsLoading', false, {
          //   root: true
          // })
          resolve(response)
        })
        .catch(error => {
          // context.dispatch('pageIsLoading', false, {
          //   root: true
          // })
          reject(error)
        })
    })
  },
  bulkUploadChapterFile(context, formData) {
    return new Promise((resolve, reject) => {
      // console.log(data);
      Vue.axios.post("/bulk-chapter-upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          context.commit('GET_CHAPTERS', response.data.chapters)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}