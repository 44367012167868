import Vue from 'vue'
const state = {
  statistics: [],
  book: null,
  books_head_all: [{
    id: "all",
    title: "Все"
  }],
  books: [],
  all_count: 0,
  all_honorarium: 0,
  headersTable: [{
      text: "Части",
      align: "start",
      sortable: false,
      value: "day_date",
    },
    {
      text: "01",
      value: "book_title"
    },
    {
      text: "02",
      value: "count"
    },
    {
      text: "03",
      value: "count_end"
    },
  ],
  condStat: null,
  chart: {

    labels: [],
    datasets: [{
        label: 'Прочтений',
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
      },
      {
        label: 'Завершенных',
        data: [],
        backgroundColor: "rgba(2, 144, 1, 0.2)",
        borderColor: "rgba(2, 144, 1, 1)",
        borderWidth: 2,
      },

    ],
  }
}
const getters = {
  statistics(state) {
    return state.statistics;
  },
  book(state) {
    return state.book;
  },
  books(state) {
    return state.books_head_all.concat(state.books);
  },
  only_books(state) {
    return state.books;
  },
  all_count(state) {
    return state.all_count;
  },
  all_honorarium(state) {
    return state.all_honorarium;
  },
  headersTable(state) {
    return state.headersTable;
  },
  condStat(state) {
    return state.condStat;
  },
  chart(state) {
    return state.chart;
  },
}
const mutations = {
  SET_STATISTICS(state, statistics) {
    state.statistics = statistics;
  },
  SET_BOOK(state, book) {
    state.book = book;
  },
  SET_BOOKS(state, books) {
    state.books = books;
  },
  SET_ALL_COUNT(state, all_count) {
    state.all_count = all_count;
  },
  SET_ALL_HONORARIUM(state, all_honorarium) {
    state.all_honorarium = all_honorarium;
  },
  SET_HEADERS(state, headers) {
    state.headersTable = headers;
  },
  SET_COND_STAT(state, condStat) {
    state.condStat = condStat;
  },
  SET_CHART(state, chart) {
    state.chart.labels = chart.labels;
    state.chart.datasets[0].data = chart.views;
    state.chart.datasets[1].data = chart.end;
  },

}



const actions = {


  getStatistics(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('SET_STATISTICS', [])
    context.commit('SET_COND_STAT', null)
    return new Promise((resolve, reject) => {
      Vue.axios.get(
          "/get-statistics-day" +
          "?book_id=" +
          data.book_id +
          "&date=" +
          data.date
        )
        .then(response => {
          context.commit('SET_STATISTICS', response.data.statistics)
          context.commit('SET_BOOKS', response.data.books)
          if (response.data.all_count) {
            context.commit('SET_ALL_COUNT', response.data.all_count)
          }
          if (response.data.all_honorarium) {
            context.commit('SET_ALL_HONORARIUM', response.data.all_honorarium)
          }
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
  getStatisticsViews(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('SET_STATISTICS', [])
    context.commit('SET_COND_STAT', null)
    return new Promise((resolve, reject) => {
      Vue.axios.get(
          "/get-statistics-views" +
          "?book_id=" +
          data.book_id +
          "&date=" +
          data.date
        )
        .then(response => {
          context.commit('SET_STATISTICS', response.data.statistics)
          context.commit('SET_BOOKS', response.data.books)
          if (response.data.book) {
            context.commit('SET_BOOK', response.data.book)
          }
          if (response.data.book && response.data.book.cond_stat) {
            context.commit('SET_COND_STAT', response.data.book.cond_stat)
          }

          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
  getStatisticsViewsPeriod(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('SET_STATISTICS', [])
    context.commit('SET_COND_STAT', null)
    return new Promise((resolve, reject) => {
      Vue.axios.get(
          "/get-statistics-views-period" +
          "?book_id=" +
          data.book_id +
          "&period=" +
          data.period
        )
        .then(response => {
          context.commit('SET_STATISTICS', response.data.statistics)
          context.commit('SET_BOOKS', response.data.books)
          if (response.data.book) {
            context.commit('SET_BOOK', response.data.book)
          }
          if (response.data.book && response.data.book.cond_stat) {
            context.commit('SET_COND_STAT', response.data.book.cond_stat)
          }
          if (response.data.chart) {
            context.commit('SET_CHART', response.data.chart)
          }

          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
  getStatisticsViewsByChapters(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('SET_STATISTICS', [])
    context.commit('SET_COND_STAT', null)
    return new Promise((resolve, reject) => {
      Vue.axios.get(
          "/get-statistics-views-chapters" +
          "?book_id=" +
          data.book_id +
          "&period=" +
          data.period
        )
        .then(response => {
          context.commit('SET_STATISTICS', response.data.statistics)
          context.commit('SET_BOOKS', response.data.books)
          context.commit('SET_BOOK', response.data.book)

          if (response.data.headersTable) {
            context.commit('SET_HEADERS', response.data.headersTable)
          }
          if (response.data.book && response.data.book.cond_stat) {
            context.commit('SET_COND_STAT', response.data.book.cond_stat)
          }


          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },




}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}