import Vue from 'vue'
const state = {
  payouts: [],
}
const getters = {
  payouts(state) {
    return state.payouts;
  },

}
const mutations = {
  SET_PAYOUTS(state, payouts) {
    state.payouts = payouts;
  },
 

}



const actions = {


  getPayouts(context) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('SET_PAYOUTS', [])
    return new Promise((resolve, reject) => {
      Vue.axios.get(
        "/get-payouts"
        )
        .then(response => {
          context.commit('SET_PAYOUTS', response.data.payouts)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
  
 



}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}