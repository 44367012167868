<template>
  <div
    v-if="
      siteSettings &&
      siteSettings.left_discount &&
      siteSettings.left_discount.length > 0
    "
    class="left-discounts"
  >
    <v-divider></v-divider>
    <router-link
      :to="{
        name: 'CategoryShow',
        params: { slug: 'all' },
        query: { sort: 'discount' },
      }"
    >
      <div class="discounts-title">Скидки сегодня</div>
      <v-row class="mx-0 justify-center">
        <v-col class="col-4 px-1" 
        v-for="(book, index) in siteSettings.left_discount" 
        :key="index"
        :index="index"
        >
          <img
            :src="book.image_small"
          />
        </v-col>
      </v-row>
    </router-link>
    <v-divider></v-divider>
  </div>
</template>
<script>
// import BookSmallCard from "./BookSmallCard.vue";
export default {
  // components: { BookSmallCard },
  computed: {
    siteSettings() {
      return this.$store.getters.siteSettings;
    },
  },
};
</script>