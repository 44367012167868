import Vue from 'vue'
const state = {
  transactions: [],
  out_commission:0, //Комиссия вывод

}
const getters = {
  transactions(state) {
    return state.transactions;
  },
  out_commission(state){
    return state.out_commission;
  }
}
const mutations = {
  GET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions;
  },
  SET_OUT_COMMISSION(state, out_commission) {
    state.out_commission = out_commission;
  },

}



const actions = {


  getTransactions(context) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('GET_TRANSACTIONS', [])
    return new Promise((resolve, reject) => {
      Vue.axios.get("/get-outin-transactions")
        .then(response => {
          context.commit('GET_TRANSACTIONS', response.data.transactions)

          if(response.data.out_commission){
            context.commit('SET_OUT_COMMISSION', response.data.out_commission)
          }
          
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
  balanceMinus(context, data) {
      return new Promise((resolve, reject) => {
      Vue.axios.post("/add-out-transaction", {
        summ: data.summ
      })
        .then(response => {
          context.commit('GET_TRANSACTIONS', response.data.transactions)
          context.dispatch('setUser', response.data.user, { root: true })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },



}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}