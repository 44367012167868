<template>
  <div class="reply-comment-form">
    <!-- <div v-if="currentUser && (currentUser.id != comment.user_id || commentEditId) "> -->
    <div v-if="currentUser">
      <v-form v-model="valid" ref="form">
        <v-textarea
          name="inputComment"
          label="Текст комментария"
          v-model="commentText"
          :rules="commentTextRules"
          rows="2"
        ></v-textarea>
        <v-btn dark color="primary" @click="replyTo">Ответить</v-btn>
      </v-form>
    </div>
    <div v-else>
      Вы должны
      <v-btn :to="{name: 'Login'}" tile link text>авторизоваться</v-btn>
    </div>
  </div>
</template>
<style>
</style>
<script>
export default {
  props: ["comment", "type", "commentable_type"],
  data: () => ({
    valid: true,
    loading: false,
    commentText: "",
    commentEditId: "",
    commentTextRules: [
      (v) => !!v || "Поле обязательно",
      (v) => !!v === v.length >= 1 || "Минимум 1 символ",
    ],
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    replyTo() {
      event.preventDefault();

      if (!this.validate()) return;

      if (this.type == "edit") {
        this.editComment();
      } else {
        this.addComment();
      }
    },
    addComment() {
      var app = this;
      this.$store
        .dispatch("comments/addComment", {
          body: app.commentText,
          parent_id: app.comment.id,
          commentable_id: app.commentableId,
          commentable_type: app.commentable_type,
        })
        .then(() => {
          // app.$emit("add-child", responce.data.comment);
          app.clearComment();
        })
        .catch(() => {
          // app.loading = false;
        });
    },
    editComment() {
      var app = this;
      this.$store
        .dispatch("comments/editComment", {
          body: app.commentText,
          comment_id: app.commentEditId,
          commentable_type: app.commentable_type,
        })
        .then(() => {
          // app.$emit("add-child", responce.data.comment);
          app.clearComment();
        })
        .catch(() => {
          // app.loading = false;
        });
    },
    clearComment() {
      this.commentText = "";
      this.$emit("hide-form", []);
      this.$refs.form.resetValidation()
      //   this.showCommentAdd = false;
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    commentableId() {
      if (this.comment){
          return this.comment.commentable_id;
      }
      return null;
    },
  },
  mounted() {
    if (this.type == "edit") {
      this.commentText = this.comment.body;
      this.commentEditId = this.comment.id;
    }
  },
};
</script>
