<template>
  <v-footer class="font-weight-medium">
    <v-col class="text-center" cols="12">
      {{ new Date().getFullYear()-1 +'-'+ new Date().getFullYear() }}  
      <strong>{{ siteTitle }}</strong>
    </v-col>
  </v-footer>
</template>
<script>
export default {
  data: function() {
    return {};
  },
  computed: {
    siteTitle() {
      return this.$store.getters.siteSettings.site_title;
    }
  }
};
</script>
