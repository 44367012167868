import Vue from 'vue'
const state = {
  requisites: [],
  requisiteTypes: [],
  requisiteList: [],
  commerceStatus: false,
}
const getters = {



  requisites(state) {
    return state.requisites;
  },
  requisiteTypes(state) {
    return state.requisiteTypes;
  },
  requisiteList(state) {
    return state.requisiteList;
  },
  commerceStatus(state) {
    return state.commerceStatus;
  },
  

}
const mutations = {

  SET_REQUISITES(state, requisites) {
    state.requisites = requisites;
  },
  SET_REQUISITE_TYPES(state, requisiteTypes) {
    state.requisiteTypes = requisiteTypes;
  },
  SET_REQUISITE_LIST(state, requisiteList) {
    state.requisiteList = requisiteList;
  },
  SET_COMMERCE_STATUS(state, status) {
    state.commerceStatus = status;
  },



}



const actions = {

  getRequisites(context) {

    context.dispatch('pageIsLoading', true, {
      root: true
    })
    return new Promise((resolve, reject) => {
      Vue.axios.get('/document/requisite')
        .then(response => {
          if (response.data.requisites) {
            context.commit('SET_REQUISITES', response.data.requisites)
          }
          context.commit('SET_REQUISITE_TYPES', response.data.requisiteTypes);
          context.commit('SET_REQUISITE_LIST', response.data.requisiteList);
          context.commit('SET_COMMERCE_STATUS', response.data.checkUserCommerce.status)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
  addRequisite(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/document/requisite/store', data)
        .then(response => {
          if (response.data.requisites) {
            context.commit('SET_REQUISITES', response.data.requisites)
          }
       
          resolve(response)
        })
        .catch(error => {
       
          reject(error)
        })
    })
  },
  updateRequisite(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.put("/document/requisite/update/"+data.id, data)
        .then(response => {
          if (response.data.requisites) {
            context.commit('SET_REQUISITES', response.data.requisites)
          }
       
          resolve(response)
        })
        .catch(error => {
       
          reject(error)
        })
    })
  },






  
  destroy(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete("/document/requisite/destroy/"+data.id)
        .then(response => {
          if (response.data.requisites) {
            context.commit('SET_REQUISITES', response.data.requisites)
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },
 


 


  

  sendCode(context, data) {

    return new Promise((resolve, reject) => {
      Vue.axios.get("/document/requisite/send-code/" + data.id)
        .then(response => {
          
          if (response.data.requisites) {
            context.commit('SET_REQUISITES', response.data.requisites)
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },
  sendVerifyCode(context, data) {

    return new Promise((resolve, reject) => {
      Vue.axios.post("/document/requisite/verify-code/" + data.id, {
          code: data.smsCode,
        })
        .then(response => {

          if (response.data.requisites) {
            context.commit('SET_REQUISITES', response.data.requisites)
          }
      
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },
  


}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}