import Vue from 'vue'
const state = {
  books: [],
  books_top: [],
  top_settings: [],
  book: null,
  category: null,
  // likes: [],
  // libraries: [],

}
const getters = {
  book(state) {
    return state.book;
  },
  category(state) {
    return state.category;
  },
  books(state) {
    return state.books;
  },
  books_top(state) {
    return state.books_top;
  },
  top_settings(state) {
    return state.top_settings;
  },
  // likes(state) {
  //   return state.likes;
  // },
  // libraries(state) {
  //   return state.libraries;
  // },
}
const mutations = {
  GET_BOOKS(state, books) {
    state.books = books;
  },
  SET_CATEGORY(state, category) {
    state.category = category;
  },
  GET_BOOKS_TOP(state, books) {
    state.books_top = books;
  },
  GET_BOOK(state, book) {
    state.book = book;
  },
  SET_BOOK_HAS_BOOK(state, hasBook) {
    state.book.hasBook = hasBook;
  },
  SET_TOP_SETTINGS(state, top_settings) {
    state.top_settings = top_settings;
  },
  // ADD_LIKES(state, likes) {
  //   state.likes = likes;
  // },
  ADD_COMMENTS_PLUS(state) {
    state.book.count_comments++;
  },
  // ADD_LIKES_COUNT(state, count) {
  //   state.book.count_likes = count;
  // },
  // ADD_LIKES_MINUS(state) {
  //   state.book.count_likes--;
  // },
  // ADD_LIBRARIES(state, libraries) {
  //   state.libraries = libraries;
  // },
  // ADD_LIBRARIES_PLUS(state) {
  //   state.book.count_libraries++;
  // },
  // ADD_LIBRARIES_MINUS(state) {
  //   state.book.count_libraries--;
  // },
}



const actions = {


  getBooks(context, data) {
    let path = '/get-books/';
    if (data.type == 'tag') {
      path = '/get-books-tag/';
    }
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    // context.commit('GET_BOOKS', [])
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    path += data.bookSlug + "?page=" + data.page + "&sorting=" + data.sort;

    // if(data.erofilters){
    //   path += "&erofilters=" + JSON.stringify(data.erofilters);
    // }

    // if(data.pricefilters){
    //   path += "&pricefilters=" + JSON.stringify(data.pricefilters);
    // }

    if(data.pricefilters){
    if(data.pricefilters.fPrice){
      path += "&f[price]=" + data.pricefilters.fPrice;
    }

    if(data.pricefilters.fComplete){
      path += "&f[complete]=" + data.pricefilters.fComplete;
    }
    }

    if(data.erofilters){
      path += "&erofilters=" + JSON.stringify(data.erofilters);
    }
    
    return new Promise((resolve, reject) => {
      Vue.axios.get(path)
        .then(response => {

          context.commit('GET_BOOKS', response.data.books.data)

          
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          if(response.data.books_top){
            context.commit('GET_BOOKS_TOP', response.data.books_top)
          }
          if(response.data.category){
            context.commit('SET_CATEGORY', response.data.category)
          }
          if(response.data.banners){
            context.commit('SET_BANNERS', response.data.banners)
          }
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  getBooksTop(context) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    return new Promise((resolve, reject) => {
      Vue.axios.get('/get-books-top')
        .then(response => {
          // console.log(response.data.books);
          context.commit('GET_BOOKS_TOP', response.data.books_top)
          if(response.data.top_settings){
            context.commit('SET_TOP_SETTINGS', response.data.top_settings)
          }

          if(response.data.banners){
            context.commit('SET_BANNERS', response.data.banners)
          }


          
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
  getBook(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('GET_BOOK', null)

    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      let path = "/get-book/" +
        data.bookSlug +
        "?page=" +
        data.page +
        "&sorting=" +
        data.sort;

      if (data.order) {
        path += "&order_id=" + data.order
      }

      Vue.axios.get(path)
        .then(response => {

          context.commit('GET_BOOK', response.data.book)
          if(response.data.book.user && response.data.book.user.subscribers_ids){
            context.commit('user/ADD_SUBSCRIBERS', response.data.book.user.subscribers_ids, {
              root: true
            })
          }
          context.commit('likes/ADD_LIKES_IDS', response.data.book.likes_ids, {
            root: true
          })
          context.commit('likes/ADD_LIKES_COUNT', response.data.book.count_likes, {
            root: true
          })
          context.commit('libraries/ADD_LIBRARIES_IDS', response.data.book.libraries_ids, {
            root: true
          })
          context.commit('libraries/ADD_LIBRARIES_COUNT', response.data.book.count_libraries, {
            root: true
          })
          context.commit('comments/SET_COMMENTS', response.data.comments.data, {
            root: true
          })
          context.commit('comments/SET_PAGE', data.page, {
            root: true
          })
          context.commit('comments/SET_PAGES', response.data.comments.last_page, {
            root: true
          })
          context.commit('comments/SET_SORT', data.sort, {
            root: true
          })

          context.dispatch('pageIsLoading', false, {
            root: true
          })

          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  // addLibrary(context, data) {
  //   return new Promise((resolve, reject) => {
  //     Vue.axios.get("/add-library/" + data.bookId)
  //       .then(response => {
  //         response.data.attached ? context.commit('ADD_LIBRARIES_PLUS') : context.commit('ADD_LIBRARIES_MINUS');
  //         context.commit('ADD_LIBRARIES', response.data.libraries_ids)
  //         resolve(response)
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  // },
  // addLike(context, data) {
  //   return new Promise((resolve, reject) => {
  //     Vue.axios.get("/add-like/" + data.bookId)
  //       .then(response => {
  //         // response.data.attached ? context.commit('ADD_LIKES_PLUS') : context.commit('ADD_LIKES_MINUS');
  //         context.commit('ADD_LIKES_COUNT', response.data.likes_ids.length)
  //         context.commit('ADD_LIKES', response.data.likes_ids)
  //         resolve(response)
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  // },
  getBooksSearch(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    // context.commit('GET_BOOKS', [])
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get('search/' +
          data.search +
          "?page=" +
          data.page)
        .then(response => {

          context.commit('GET_BOOKS', response.data.books.data)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  getBookBuyLink(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/order', {
          product_id: data.book_id,
          type: 'book',
          return_url: window.location.href,
        })
        .then(response => {

          if (response.data.hasBook) {
            if (data.namespace == 'book') {
              context.commit('SET_BOOK_HAS_BOOK', response.data.hasBook);
            }
          }
          //Если нам вернули баланс изменяем его у текущего пользователя
          if (response.data.balance) {
            context.commit('SET_USER_BALANCE', response.data.balance, {
              root: true
            });
          }

          resolve(response);
        })
        .catch(error => {
          reject(error)
        })
    })
  },

}

export default {
  // namespaced: true,
  state,
  mutations,
  getters,
  actions
}