import Vue from 'vue'
const state = {

}
const getters = {

}
const mutations = {
 
}



const actions = {
  addSupport(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/support', {
            body: data.body,
            name: data.name,
            email: data.email,
            support_type: data.support_type,
            topic: data.topic,
          })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  
}

export default {
  // namespaced: true,
  state,
  mutations,
  getters,
  actions
}