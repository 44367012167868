import Vue from 'vue'
const state = {
  transactions: [],
}
const getters = {
  transactions(state) {
    return state.transactions;
  },
}
const mutations = {
  GET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions;
  },

}



const actions = {


  getTransactions(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('GET_TRANSACTIONS', [])
    return new Promise((resolve, reject) => {
      Vue.axios.get(
        "/get-transactions" +
          "?page=" +
          data.page +
          "&dates=" +
          data.dates
        )
        .then(response => {
          context.commit('GET_TRANSACTIONS', response.data.transactions.data)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
 



}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}