import Vue from 'vue'
const state = {
  books: [],
  series: [],
  alltags: [],
  authors: [],
  coauthors: [],
  // eroFiltersToEdit: [],
  categories: [],
  book: null,
  books_loyalty: [],
  book_loyalty: 0,


}
const getters = {
  book(state) {
    return state.book;
  },
  books(state) {
    return state.books;
  },
  books_loyalty(state) {
    return state.books_loyalty;
  },
  book_loyalty(state) {
    return state.book_loyalty;
  },
  series(state) {
    return state.series;
  },
  alltags(state) {
    return state.alltags;
  },
  authors(state) {
    return state.authors;
  },
  coauthors(state) {
    return state.coauthors;
  },
  categories(state) {
    return state.categories;
  },
  // eroFiltersToEdit(state) {
  //   return state.eroFiltersToEdit;
  // },

}
const mutations = {
  GET_BOOKS(state, books) {
    state.books = books;
  },
  GET_BOOK(state, book) {
    state.book = book;
    if(book && book.loyalty){
      state.book_loyalty = state.book.loyalty;
    }
    
    // this.SET_BOOK_LOYALTY(book.loyalty);
    // this.commit('SET_BOOK_LOYALTY', 0)

  },
  SET_BOOK_LOYALTY(state, loyalty) {
    state.book_loyalty = loyalty;
  },
  SET_BOOKS_LOYALTY(state, books) {
    state.books_loyalty = books;
  },
  GET_SERIES(state, series) {
    state.series = series;
  },
  GET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_ALLTAGS(state, alltags) {
    state.alltags = alltags;
  },
  SET_AUTHORS(state, authors) {
    state.authors = authors;
  },
  SET_COAUTHORS(state, coauthors) {
    state.coauthors = coauthors;
  },
  SET_ERO_FILTERS(state, eroFiltersToEdit) {
    // state.eroFiltersToEdit = eroFiltersToEdit;
    state.book.eroFiltersToEdit = eroFiltersToEdit;
  },


}



const actions = {


  getBooks(context) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('GET_BOOKS', [])
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get("/books-my")
        .then(response => {

          context.commit('GET_BOOKS', response.data.books)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  getStart(context) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('GET_BOOK', null)
    return new Promise((resolve, reject) => {
      Vue.axios.get("/book/create")
        .then(response => {
          let series = response.data.series;
          if (series.length > 0) {
            series.unshift({
              id: "",
              title: "Без цикла"
            });
          }
          context.commit('GET_SERIES', series)
          context.commit('SET_ALLTAGS', response.data.alltags)
          context.commit('SET_AUTHORS', response.data.authors)
          context.commit('SET_COAUTHORS', response.data.coauthors)
          context.commit('GET_CATEGORIES', response.data.categories)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  getEditBook(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('GET_BOOK', null)
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get("/book/" + data.book_id + "/edit")
        .then(response => {

          context.commit('GET_BOOK', response.data.book)
          let series = response.data.series;
          if (series.length > 0) {
            series.unshift({
              id: "",
              title: "Без цикла"
            });
          }

          context.commit('GET_SERIES', series)
          context.commit('SET_ALLTAGS', response.data.alltags)
          context.commit('SET_AUTHORS', response.data.authors)
          context.commit('SET_COAUTHORS', response.data.coauthors)
          context.commit('GET_CATEGORIES', response.data.categories)
          if(response.data.book.eroFiltersToEdit){
            context.commit('SET_ERO_FILTERS', response.data.book.eroFiltersToEdit)
          }
          
          context.commit('cabinet_chapters/GET_CHAPTERS', response.data.book.chapters, {
            root: true
          })

          if(response.data.promoCodes){
            context.commit('cabinet_promo/SET_PROMO', response.data.promoCodes, {
              root: true
            })
          }
          
          
          // if(response.data.promoDays){
            context.commit('cabinet_promo/SET_PROMO_DAYS', response.data.promoDays, {
              root: true
            })
          // }

          if(response.data.discounts){
            context.commit('cabinet_promo/SET_DISCOUNTS', response.data.discounts, {
              root: true
            })
          }

          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  addBook(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/book", data.formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          context.commit('GET_BOOK', response.data.book)
          if (response.data.user) {
            context.dispatch('setUser', response.data.user, {
              root: true
            })
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateBook(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/book/" + data.book_id, data.formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          context.commit('GET_BOOK', response.data.book)
          if(response.data.book.eroFiltersToEdit){
            context.commit('SET_ERO_FILTERS', response.data.book.eroFiltersToEdit)
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateCover(context, data) {
    return new Promise(() => {
      // console.log(data);
      Vue.axios.post("/book/" + data.book_id + "/update-cover", data.formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      // .then(response => {
      //   context.commit('SET_USER', response.data.user, {
      //     root: true
      //   });
      //   resolve(response)
      // })
      // .catch(error => {
      //   context.dispatch('pageIsLoading', false, {
      //     root: true
      //   })
      //   reject(error)
      // })
    })
  },
  updateBooksOrder(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/book/updateBooksOrder", {
          books: data.books
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateOrder(context, data) {
    context.commit('cabinet_chapters/GET_CHAPTERS', data.chapters, {
      root: true
    })
    return new Promise((resolve, reject) => {
      Vue.axios.post("/chapter/" + data.book_id + "/updateOrder", {
          chapters: data.chapters
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dellBook(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete("/book/" + data.deleteData[0])
        .then(response => {
          context.commit('GET_BOOKS', response.data.books)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  addSalesRequest(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/book/" + data.book_id + "/add-sales-request", {
          sales_email: data.sales_email,
          sales_phone: data.sales_phone,
        })
        .then(response => {
          // context.commit('GET_BOOK', response.data.book)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /*соавторы*/
  addEroFilters(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/add-ero-filters/"+data.book_id, {
        eroContact: data.eroContact,
        contact: data.contact,
        ageLovers: data.ageLovers,
        ageHero: data.ageHero,
        ageHeroine: data.ageHeroine,
        heroineinPosition: data.heroineinPosition,
        heroWealth: data.heroWealth,
        heroineWealth: data.heroineWealth,
        natureContact: data.natureContact,
        heroType: data.heroType,
        heroineType: data.heroineType,
        socialStatus: data.socialStatus,
   
        })
        .then(response => {
          //context.commit('SET_AUTHORS', response.data.authors)
          context.commit('SET_ERO_FILTERS', response.data.eroFiltersToEdit)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },
  addCoauthor(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/coauthor/"+data.book_id+"/"+data.user_id, {
          // book_id: 11
   
        })
        .then(response => {
          //context.commit('SET_AUTHORS', response.data.authors)
          context.commit('SET_COAUTHORS', response.data.coauthors)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },
 
  dellCoauthor(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete("/coauthor/"+data.book_id+"/"+data.user_id, {
   
        })
        .then(response => {
          //context.commit('SET_AUTHORS', response.data.authors)
          context.commit('SET_COAUTHORS', response.data.coauthors)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },
  removeSale(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/book-remove-sale/"+data.book_id)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  changePrice(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/book-change-price/"+data.book_id, {
        new_price: data.new_price
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getMyBooksLoyalty(context) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('SET_BOOKS_LOYALTY', [])
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get("/get-mybooks-loyalty")
        .then(response => {
          context.commit('SET_BOOKS_LOYALTY', response.data.books)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  updateLoyalty(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/book/" + data.book_id + "/update-loyalty", {
          percent: data.percent,
        })
        .then(response => {
          context.commit('SET_BOOK_LOYALTY', response.data.loyalty)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dellLoyalty(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/book/" + data.book_id + "/dell-loyalty")
        .then(response => {
          context.commit('SET_BOOK_LOYALTY', response.data.loyalty)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}