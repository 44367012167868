<template>
  <div
    v-if="comment"
    :id="'comm' + comment.id"
    class="comment-card"
    :class="!comment.parent_id ? 'parent' : ''"
  >
    <div class="comment-card-block">
      <div class="comment-avatar">
        <!-- {{comment.numberInTree}} -->
        <img :src="comment.user.avatar_url" />
      </div>
      <div class="comment-right">
        <div class="one-comment-head">
          <router-link
            :to="{ name: 'UserShow', params: { userName: comment.user.name } }"
            >{{ comment.user.public_name }}</router-link
          >
          <!-- <div
            class="one-comment-date"
          >id:{{ comment.id }} | tree - {{ comment.tree_id }}| parent - {{ comment.parent_id }} | {{ comment.public_date }}</div>-->
          <div class="one-comment-date">{{ comment.public_date }}</div>

          <div v-if="!isDelete" class="comment-actions">
            <v-btn
              v-if="currentUser && currentUser.id == comment.user_id"
              @click.stop="showEditForm"
              icon
            >
              <v-icon>$vuetify.icons.square_edit_outline</v-icon>
            </v-btn>
            <v-btn
              v-if="
                currentUser &&
                (currentUser.id == comment.user_id ||
                  currentUser.id == entity_user_id)
              "
              @click.stop="deleteComment"
              icon
            >
              <v-icon>$vuetify.icons.delete</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="one-comment-body">{{ comment.body }}</div>
        <!-- <a
          v-if="currentUser && !isDelete 
          && comment.parent_id == comment.tree_id 
          && currentUser.id != comment.user_id"
          text
          tile
          @click="showForm"
          class="one-comment-reply"
        >{{ replyToComment ? 'Скрыть' :'Ответить' }}</a> -->
        <a
          v-if="
            currentUser &&
            !isDelete &&
            +comment.numberInTree < 10 &&
            currentUser.id != comment.user_id
          "
          text
          tile
          @click="showForm"
          class="one-comment-reply"
          >{{ replyToComment ? "Скрыть" : "Ответить" }}</a
        >
      </div>
    </div>
    <comment-form
      v-if="replyToComment"
      :comment="comment"
      :type="typeForm"
      :commentable_type="commentable_type"
      @add-child="addChild"
      @edit-comment="editComment"
      @hide-form="replyToComment = false"
    ></comment-form>
    <div class="child-list">
      <!-- <div> -->
      <div
        v-if="!this.$parent.child && children_count > 0"
        class="one-comment-tree-head"
      >
        <span v-if="!isOpen && $vuetify.breakpoint.smAndUp"
          >Последний комментарий в ветке:</span
        >
        <v-spacer></v-spacer>
        <span v-if="$vuetify.breakpoint.smAndUp">
          В ветке {{ children_count }} Комментариев.
          <v-btn
            :loading="loading"
            class="ml-2"
            tile
            text
            @click="openTree"
            v-if="children_count > 1"
            >{{ isOpen ? "Скрыть" : "Показать" }}</v-btn
          >
        </span>

        <v-btn
          :loading="loading"
          class="ml-2"
          tile
          text
          @click="openTree"
          v-if="children_count > 1 && $vuetify.breakpoint.xsOnly"
          >{{ children_count }} Комментариев
          {{ isOpen ? "Скрыть" : "Показать" }}</v-btn
        >
      </div>
      <comment-list
        v-if="currentTree && currentTree.length > 0"
        :comments="currentTree"
        :entity_user_id="entity_user_id"
        :commentable_type="commentable_type"
        :child="true"
      ></comment-list>
    </div>
  </div>
</template>
<script>
import CommentForm from "./CommentForm.vue";
export default {
  components: { CommentForm },
  name: "CommentCard",

  props: ["comment", "entity_user_id", "commentable_type"],
  data() {
    return {
      currentComment: this.comment,
      commentUserId: this.comment.user_id,
      commentId: this.comment.id,
      commentTreeId: this.comment.tree_id,
      //children_count: this.comment.children_count,
      isOpen: false, //открыта ли дерево комментариев
      loading: false, //Прогресс загрузки
      replyToComment: false,
      // currentTree: [], //Текущий выбранный коммент
      // last_children: [], //последний ребенок, оборачиваем сразу в массив
      // comments: [], //ВСе дети
      loadingTree: [], //Здесь будет храниться загруженные комментарии
      isLoading: false, //Комментарии уже загружены,
      typeForm: "add", //ДОбавляем комментарий или редактируем
    };
  },
  methods: {
    openTree() {
      this.isOpen = !this.isOpen;
    },
    addChild(new_comment) {
      this.$eventBus.$emit("update-last-comment", new_comment);
    },
    showForm() {
      this.typeForm = "add";
      this.replyToComment = !this.replyToComment;
    },
    showEditForm() {
      if (this.replyToComment) {
        this.typeForm = "add";
        this.replyToComment = false;
      } else {
        this.typeForm = "edit";
        this.replyToComment = true;
      }
    },
    editComment(edit_comment) {
      this.$eventBus.$emit("edit-comment", edit_comment);
    },
    deleteComment() {
      this.$eventBus.$emit("delete-comment", this.commentId);
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    isDelete() {
      if (this.comment.deleted_at) {
        return true;
      }
      return false;
    },
    currentTree() {
      if (this.isOpen) {
        return this.comments;
      } else {
        return this.last_children;
      }
    },
    last_children() {
      if (this.comment.last_children) {
        return [this.comment.last_children]; //последний ребенок, оборачиваем сразу в массив
      } else {
        return this.comments;
      }
    },
    comments() {
      return this.comment.children;
    },
    children_count() {
      return this.comment.children_count;
    },
  },
  mounted() {
    //если коммент на который мы ссылаемся родитель, мы его раскрываем
    if (
      !this.comment.parent_id &&
      this.$route.hash &&
      this.$route.hash == "#comm" + this.comment.id
    ) {
      this.openTree();
      this.$vuetify.goTo(this.$route.hash);
    }
  },
};
</script>
