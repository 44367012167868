import Vue from 'vue'
const state = {
  book: null,
  chapter: null,
  body: null,
  show_buy: false,
  adult_block: false,
  similar: [],
 
}
const getters = {
  book(state) {
    return state.book;
  },
  chapter(state) {
    return state.chapter;
  },
  body(state){
    return state.chapter && state.chapter.body_by_pages ? state.chapter.body_by_pages : null;
  },
  body_speech(state){
    return state.chapter && state.chapter.body_speech ? state.chapter.body_speech : null;
  },
  show_buy(state) {
    return state.show_buy;
  },
  adult_block(state) {
    return state.adult_block;
  },
  similar(state) {
    return state.similar;
  },

}
const mutations = {
  GET_BOOK(state, book) {
    state.book = book;
  },
  GET_CHAPTER(state, chapter) {
    state.chapter = chapter;
  },
  SHOW_BUY(state, show_buy) {
    state.show_buy = show_buy;
  },
  ADULT_BLOCK(state, adult_block) {
    state.adult_block = adult_block;
  },
  SET_SIMILAR(state, similar) {
    state.similar = similar;
  },

}



const actions = {

  getBook(context, data) {
    context.dispatch('pageIsLoading', true, { root: true } )
    // let path = "/reader-get/" + data.book_id;
    let path = "/reader-get/" + data.book_slug;
    if(data.chapter_id){
      path = path + "/" + data.chapter_id;
    }

    if(data.order){
      path += "?order_id="+data.order
    }
    return new Promise((resolve, reject) => {
      Vue.axios.get(path)
        .then(response => {
      
          context.commit('GET_BOOK', response.data.book)     
          context.commit('GET_CHAPTER', response.data.chapter)   
          context.commit('SHOW_BUY', response.data.showBuy) 
          context.commit('ADULT_BLOCK', response.data.adultBlock)
          context.commit('SET_SIMILAR', response.data.similar)

          if(response.data.banners){
            context.commit('SET_BANNERS', response.data.banners, { root: true } )
          }

          context.dispatch('pageIsLoading', false, { root: true } )
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, { root: true } )
          reject(error)
        })
    })

    
  },


}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}