import Vue from 'vue'
import VueRouter from 'vue-router'

import middlewarePipeline from './middlewarePipeline'
import store from '../store'

import routes from './routes'

// import Home from '../views/Home.vue'

Vue.use(VueRouter)



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  linkExactActiveClass: '',
  linkActiveClass: '',
})

router.beforeEach((to, from, next) => {

  //сохранени е партнерской ссылки
  if (to.query && to.query.p && to.query.p !== undefined) {
    // console.log(to.query.p);
    localStorage.setItem("partner_id", to.query.p);
  } 


  if (!to.meta || !to.meta.middleware) {
      return next()
  }
  const middleware = to.meta.middleware

  const context = {
      to,
      from,
      next,
      store
  }


  return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
  })

})

export default router