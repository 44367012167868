import Vue from 'vue'
const state = {
  documentsStatus: null,
  documentStatus: 0,
  statement: null,
  docTemplate: null,
  instruction: null,
  commerceStatus: false,
  errors: null,
  showCode: false,
  history: [],
  doc64: null,
  docId: null,
}
const getters = {



  documentsStatus(state) {
    return state.documentsStatus;
  },
  statement(state) {
    return state.statement;
  },
  docTemplate(state) {
    return state.docTemplate;
  },
  instruction(state) {
    return state.instruction;
  },
  commerceStatus(state) {
    return state.commerceStatus;
  },
  errors(state) {
    return state.errors;
  },


  documentStatus(state) {
    return state.documentStatus;
  },
  history(state) {
    return state.history;
  },
  showCode(state) {
    return state.showCode;
  },
  doc64(state) {
    return state.doc64;
  },
  docId(state) {
    return state.docId;
  },



}
const mutations = {


  SET_DOC_STATUS(state, documentsStatus) {
    state.documentsStatus = documentsStatus;
  },


  SET_STATEMENT(state, statement) {
    state.statement = statement;
  },
  SET_DOC_TEMPLATE(state, document) {
    state.docTemplate = document;
  },
  SET_DOC_INSTRUCTION(state, instruction) {
    state.instruction = instruction;
  },
  SET_COMMERCE_STATUS(state, status) {
    state.commerceStatus = status;
  },
  SET_ERRORS(state, errors) {
    state.errors = errors;
  },

  SET_DOCUMENT_STATUS(state, documentStatus) {
    state.documentStatus = documentStatus;
  },
  SET_HISTORY(state, history) {
    state.history = history;
  },
  SET_SHOW_CODE(state, showCode) {
    state.showCode = showCode;
  },
  CLEAR_AGREEMENT(state) {
    state.doc64 = null;
    state.documentStatus = 0;
    state.history = [];
    state.showCode = false;
  },
  CLEAR_APPENDIX(state) {
    state.doc64 = null;
    state.documentStatus = 0;
    state.showCode = false;
  },
  SET_DOCUMENT_64(state, doc64) {
    state.doc64 = doc64;
  },
  SET_DOCUMENT_ID(state, docId) {
    state.docId = docId;
  },


}



const actions = {
  checkCommerceDocumentsStatus(context) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    return new Promise((resolve, reject) => {
      Vue.axios.get("/document/checkCommerceDocumentsStatus")
        .then(response => {

          context.commit('SET_DOC_STATUS', response.data.documentsStatus)

          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  getStatementStart(context) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    return new Promise((resolve, reject) => {
      Vue.axios.get("/document/statement-document")
        .then(response => {

          context.commit('SET_STATEMENT', response.data.statement)

          if (response.data.instruction) {
            context.commit('SET_DOC_INSTRUCTION', response.data.instruction)
          }

          if (response.data.checkUserCommerce) {
            context.commit('SET_COMMERCE_STATUS', response.data.checkUserCommerce.status)
            if (response.data.checkUserCommerce && !response.data.checkUserCommerce.status) {
              context.commit('SET_ERRORS', response.data.checkUserCommerce.errors)
            }
          }




          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  dellStatement(context) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete("/document/statement-document-dell")
        .then(response => {
          context.commit('SET_STATEMENT', response.data.statement)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },
  getStatementTemplate(context) {

    return new Promise((resolve, reject) => {
      Vue.axios.get("/document/statement-document-template")
        .then(response => {
          context.commit('SET_DOC_TEMPLATE', response.data.docTemplate)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },


  uploadStatementFile(context, formData) {
    // context.dispatch('pageIsLoading', true, {
    //   root: true
    // })
    return new Promise((resolve, reject) => {
      // console.log(data);
      Vue.axios.post("/document/statement-document-upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          context.commit('SET_STATEMENT', response.data.statement)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },


  getAgreement(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })

    context.commit('CLEAR_AGREEMENT');


    return new Promise((resolve, reject) => {
      Vue.axios.get("/document/agreement-document/" + data.type)
        .then(response => {

          // context.commit('SET_STATEMENT', response.data.statement)

          if (response.data.doc64) {
            context.commit('SET_DOCUMENT_64', response.data.doc64)
          }
          if (response.data.docId) {
            context.commit('SET_DOCUMENT_ID', response.data.docId)
          }
          if (response.data.documentStatus) {
            context.commit('SET_DOCUMENT_STATUS', response.data.documentStatus)
          }
          if (response.data.history) {
            context.commit('SET_HISTORY', response.data.history)
          }
          if (response.data.showCode) {
            context.commit('SET_SHOW_CODE', response.data.showCode)
          }

          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  getAppendix(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })

    context.commit('CLEAR_APPENDIX');


    return new Promise((resolve, reject) => {
      let complete = "";
      if (data.complete) {
        complete = "?complete=1";
      }
      Vue.axios.get("/document/appendix-document/" + data.bookId + complete)
        .then(response => {

          if (response.data.doc64) {
            context.commit('SET_DOCUMENT_64', response.data.doc64)
          }
          if (response.data.docId) {
            context.commit('SET_DOCUMENT_ID', response.data.docId)
          }

          if (response.data.documentStatus) {
            context.commit('SET_DOCUMENT_STATUS', response.data.documentStatus)
          }
          if (response.data.showCode) {
            context.commit('SET_SHOW_CODE', response.data.showCode)
          }
          if (response.data.book) {

            context.commit('cabinet_books/GET_BOOK', response.data.book, {
              root: true
            })
            context.commit('cabinet_chapters/GET_CHAPTERS', response.data.book.chapters, {
              root: true
            })
          }

          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },

  sendCodeAgreement(context, data) {

    return new Promise((resolve, reject) => {
      Vue.axios.get("/document/agreement-send-code/" + data.docId)
        .then(response => {

          if (response.data.showCode) {
            context.commit('SET_SHOW_CODE', response.data.showCode)
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },
  sendVerifyCodeAgreement(context, data) {

    return new Promise((resolve, reject) => {
      Vue.axios.post("/document/agreement-verify-code/" + data.docId, {
          code: data.smsCode,
        })
        .then(response => {

          if (response.data.documentStatus) {
            context.commit('SET_DOCUMENT_STATUS', response.data.documentStatus)
          }
          if (response.data.history) {
            context.commit('SET_HISTORY', response.data.history)
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },
  sendCodeAppendix(context, data) {

    return new Promise((resolve, reject) => {
      Vue.axios.get("/document/appendix-send-code/" + data.docId)
        .then(response => {

          if (response.data.showCode) {
            context.commit('SET_SHOW_CODE', response.data.showCode)
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },
  sendVerifyCodeAppendix(context, data) {

    return new Promise((resolve, reject) => {
      Vue.axios.post("/document/appendix-verify-code/" + data.docId, {
          code: data.smsCode,
          chpid: data.chpid,
          price: data.price,
        })
        .then(response => {

          if (response.data.documentStatus) {
            context.commit('SET_DOCUMENT_STATUS', response.data.documentStatus)
          }

          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },

  getHistoryDocument(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })

    context.commit('SET_HISTORY_DOCUMENT', null);


    return new Promise((resolve, reject) => {
      Vue.axios.get("/document/history/" + data.type + "/" + data.docId)
        .then(response => {
          if (response.data.doc64) {
            context.commit('SET_DOCUMENT_64', response.data.doc64)
          }

          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}