import Vue from 'vue'
const state = {
  series: [],
  serie: null,
  likes: [],
  libraries: [],

}
const getters = {
  serie(state) {
    return state.serie;
  },
  series(state) {
    return state.series;
  },
  likes(state) {
    return state.likes;
  },
  libraries(state) {
    return state.libraries;
  },
}
const mutations = {
  GET_SERIES(state, series) {
    state.series = series;
  },
  GET_SERIE(state, serie) {
    state.serie = serie;
  },
  ADD_LIKES(state, likes) {
    state.likes = likes;
  },
  ADD_LIBRARIES(state, libraries) {
    state.libraries = libraries;
  },
}



const actions = {


  getSeries(context) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('GET_SERIES', [])
    return new Promise((resolve, reject) => {
      Vue.axios.get("/series-my")
        .then(response => {

          context.commit('GET_SERIES', response.data.series)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  getSerie(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('GET_SERIE', [])
    return new Promise((resolve, reject) => {
      Vue.axios.get(
          "/serie/" + data.serie_id + "/edit"
        )
        .then(response => {
          // console.log(response.data.serie)
          context.commit('GET_SERIE', response.data.serie)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  addSerie(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post(
          "/serie", {
            title: data.title,
            body: data.body,
            completed: data.completed
          }
        )
        .then(response => {
          context.commit('GET_SERIE', response.data.serie)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateSerie(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.put(
            "/serie/"+data.serie_id,
            {
              title: data.title,
              body: data.body,
              completed: data.completed
            }
          )
        .then(response => {
          context.commit('GET_SERIE', response.data.serie)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  dellSerie(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.delete("/serie/" + data.deleteData[0])
        .then(response => {
          context.commit('GET_SERIES', response.data.series)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },


}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}