<template>
  <div v-if="siteSettings && siteSettings.categories">
    <v-list-item
      v-if="currentUser && currentUser.vip"
      :to="{
        name: 'CategoryShow',
        params: { slug: 'all' },
        query: { sort: 'loyalty' },
      }"
      exact
      class="vip-color"
      link
    >
      <v-list-item-icon>
        <v-icon color="darken-1">$vuetify.icons.folder</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>VIP-КАТАЛОГ</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <left-discount></left-discount>
    <v-list-item @click="showCategories = !showCategories" exact class="" link>
      <v-list-item-icon>
        <v-icon v-if="!showCategories" color="primary"
          >$vuetify.icons.folder</v-icon
        >
        <v-icon v-else color="primary">$vuetify.icons.folder_open</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>ЖАНРЫ</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon v-if="!showCategories">$vuetify.icons.menu_right</v-icon>
        <v-icon v-else>$vuetify.icons.menu_down</v-icon>
      </v-list-item-action>
    </v-list-item>

    <!-- <v-subheader class="mt-4 grey--text text--darken-1">
      <v-list-item-action>
        <v-icon color="grey darken-1">$vuetify.icons.book_open_page_variant</v-icon>
      </v-list-item-action>Категории
    </v-subheader> -->
    <v-treeview
      v-if="showCategories"
      class="category_tree"
      activatable
      open-on-click
      :items="siteSettings.categories"
    >
      <template slot="label" slot-scope="props">
        <!-- <v-icon>{{
          props.open ? "$vuetify.icons.folder_open" : "$vuetify.icons.folder"
        }}</v-icon> -->

        <v-btn
          class="act pl-0"
          link
          text
          tile
          small
          :to="{ name: 'CategoryShow', params: { slug: props.item.slug } }"
          >{{ props.item.name }}</v-btn
        >
        <!-- <v-btn v-else class="act" link text tile small :to="{name: 'CategoryAll'}">{{ props.item.name }}</v-btn> -->
      </template>
    </v-treeview>

    <v-list-item :to="{ name: 'BlogAll' }" exact class="small-list" link>
      <v-list-item-icon>
        <v-icon color="grey darken-1">$vuetify.icons.folder</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>БЛОГИ</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item :to="{ name: 'ContestAll' }" exact class="small-list" link>
      <v-list-item-icon>
        <v-icon color="grey darken-1">$vuetify.icons.folder</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>КОНКУРСЫ</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
  </div>
</template>
<script>
import LeftDiscount from "./LeftDiscount.vue";
import { mapGetters } from "vuex";
export default {
  //   props: {
  //     items: String
  //   },
  components: {
    LeftDiscount,
  },
  data: function () {
    return {
      showCategories: false,
      //   categories: this.items ? JSON.parse(this.items) : [],
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
    siteSettings() {
      return this.$store.getters.siteSettings;
    },
  },
  mounted() {
    // console.log(this.$store.getters.globalCategories);
  },
};
</script>
