import Vue from 'vue'
const state = {

}
const getters = {

}
const mutations = {
  // ADD_REPORT(state, payload) {
  //   console.log(payload.type)
  //   // state.books = books;
  //   // findComments(state, payload.comment, payload.type);
  //   // state.comments.unshift(comment);
  // },

 
}



const actions = {
  addReport(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/report', {
            body: data.body,
            reporttable_id: data.reporttable_id,
            reporttable_type: data.reporttable_type,
            report_type: data.report_type,
          })
        .then(response => {
          // context.commit('GET_COMMENTS', response.data.comments.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  
}

export default {
  // namespaced: true,
  state,
  mutations,
  getters,
  actions
}