import Vue from 'vue'
const state = {
  page: null,
}
const getters = {
  page(state) {
    return state.page;
  },
}
const mutations = {
  GET_PAGE(state, page) {
    state.page = page;
  },
}



const actions = {
  getPage(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('GET_PAGE', null)
    return new Promise((resolve, reject) => {
      Vue.axios.get("/get-page/" + data.page_slug)
        .then(response => {
          context.commit('GET_PAGE', response.data.page)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },

}

export default {
  // namespaced: true,
  state,
  mutations,
  getters,
  actions
}