import Vue from 'vue'
const state = {

  books: [],
  pass: null,
 
}
const getters = {
  books(state) {
    return state.books;
  },
  pass(state) {
    return state.pass;
  },
 
}
const mutations = {
  SET_BOOKS(state, books) {
    state.books = books;
  },
  SET_PASS(state, pass) {
    state.pass = pass;
  },
 
}



const actions = {
  getPurchases(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('SET_BOOKS', [])
    return new Promise((resolve, reject) => {
      Vue.axios.get(
          "/account/get-purchases" +
          "?page=" +
          data.page +
          "&sorting=" +
          data.sort
        )
        .then(response => {
          context.commit('SET_BOOKS', response.data.books.data)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
  updateAccountImage(context, data) {
    return new Promise((resolve, reject) => {
      // console.log(data);
      Vue.axios.post("/account/settings/updateAvatar", data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          context.commit('SET_USER', response.data.user, {
            root: true
          });
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
  updateAccount(context, data) {
    // console.log(data);
    return new Promise((resolve, reject) => {
      Vue.axios.post("/account/settings/update", data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          context.commit('SET_USER', response.data.user, {
            root: true
          });
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getEditAuthor(context) {
    // console.log(data);
    return new Promise((resolve, reject) => {
      Vue.axios.get("/account/settings/editPass")
        .then(response => {
          context.commit('SET_PASS', response.data.pass);
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateAccountAuthor(context, data) {
    // console.log(data);
    return new Promise((resolve, reject) => {
      Vue.axios.post("/account/settings/updateAuthor", data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          context.commit('SET_USER', response.data.user, {
            root: true
          });
          context.commit('SET_PASS', response.data.pass);
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateAccountPassword(context, data) {
    // console.log(data);
    return new Promise((resolve, reject) => {
      Vue.axios.post("/account/settings/updatePassword", data)
        .then(response => {
          context.commit('SET_USER', response.data.user, {
            root: true
          });
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  socialAdd(context, data) {

    return new Promise((resolve, reject) => {
      Vue.axios.post("/account/settings/socialAdd/" + data.provider, data.response)
        .then(response => {
          context.commit('SET_USER', response.data.user, {
            root: true
          });
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },



}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}