var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.comment)?_c('div',{staticClass:"comment-card",class:!_vm.comment.parent_id ? 'parent' : '',attrs:{"id":'comm' + _vm.comment.id}},[_c('div',{staticClass:"comment-card-block"},[_c('div',{staticClass:"comment-avatar"},[_c('img',{attrs:{"src":_vm.comment.user.avatar_url}})]),_c('div',{staticClass:"comment-right"},[_c('div',{staticClass:"one-comment-head"},[_c('router-link',{attrs:{"to":{ name: 'UserShow', params: { userName: _vm.comment.user.name } }}},[_vm._v(_vm._s(_vm.comment.user.public_name))]),_c('div',{staticClass:"one-comment-date"},[_vm._v(_vm._s(_vm.comment.public_date))]),(!_vm.isDelete)?_c('div',{staticClass:"comment-actions"},[(_vm.currentUser && _vm.currentUser.id == _vm.comment.user_id)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.showEditForm($event)}}},[_c('v-icon',[_vm._v("$vuetify.icons.square_edit_outline")])],1):_vm._e(),(
              _vm.currentUser &&
              (_vm.currentUser.id == _vm.comment.user_id ||
                _vm.currentUser.id == _vm.entity_user_id)
            )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteComment($event)}}},[_c('v-icon',[_vm._v("$vuetify.icons.delete")])],1):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"one-comment-body"},[_vm._v(_vm._s(_vm.comment.body))]),(
          _vm.currentUser &&
          !_vm.isDelete &&
          +_vm.comment.numberInTree < 10 &&
          _vm.currentUser.id != _vm.comment.user_id
        )?_c('a',{staticClass:"one-comment-reply",attrs:{"text":"","tile":""},on:{"click":_vm.showForm}},[_vm._v(_vm._s(_vm.replyToComment ? "Скрыть" : "Ответить"))]):_vm._e()])]),(_vm.replyToComment)?_c('comment-form',{attrs:{"comment":_vm.comment,"type":_vm.typeForm,"commentable_type":_vm.commentable_type},on:{"add-child":_vm.addChild,"edit-comment":_vm.editComment,"hide-form":function($event){_vm.replyToComment = false}}}):_vm._e(),_c('div',{staticClass:"child-list"},[(!this.$parent.child && _vm.children_count > 0)?_c('div',{staticClass:"one-comment-tree-head"},[(!_vm.isOpen && _vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v("Последний комментарий в ветке:")]):_vm._e(),_c('v-spacer'),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(" В ветке "+_vm._s(_vm.children_count)+" Комментариев. "),(_vm.children_count > 1)?_c('v-btn',{staticClass:"ml-2",attrs:{"loading":_vm.loading,"tile":"","text":""},on:{"click":_vm.openTree}},[_vm._v(_vm._s(_vm.isOpen ? "Скрыть" : "Показать"))]):_vm._e()],1):_vm._e(),(_vm.children_count > 1 && _vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticClass:"ml-2",attrs:{"loading":_vm.loading,"tile":"","text":""},on:{"click":_vm.openTree}},[_vm._v(_vm._s(_vm.children_count)+" Комментариев "+_vm._s(_vm.isOpen ? "Скрыть" : "Показать"))]):_vm._e()],1):_vm._e(),(_vm.currentTree && _vm.currentTree.length > 0)?_c('comment-list',{attrs:{"comments":_vm.currentTree,"entity_user_id":_vm.entity_user_id,"commentable_type":_vm.commentable_type,"child":true}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }