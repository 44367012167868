<template>
    <v-overlay opacity="0.2" v-if="loadPage">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
</template>
<script>
export default {
   computed:{
     loadPage(){
       return this.$store.getters.loadPage;
     }
   }
}
</script>
