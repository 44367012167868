import Vue from 'vue'
const state = {
  posts: [],
  post: null,
  category: null,
  likes: [],
  libraries: [],

}
const getters = {
  post(state) {
    return state.post;
  },
  posts(state) {
    return state.posts;
  },
  category(state) {
    return state.category;
  },
}
const mutations = {
  GET_POSTS(state, posts) {
    state.posts = posts;
  },
  GET_POST(state, post) {
    state.post = post;
  },
  GET_CATEGORY(state, category) {
    state.category = category;
  },

}



const actions = {


  getPosts(context, data) {
    let path = '/info/get-posts/';
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    // context.commit('GET_BOOKS', [])
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get(path +
          data.categorySlug +
          "?page=" +
          data.page)
        .then(response => {

          context.commit('GET_POSTS', response.data.posts.data)
          context.commit('GET_CATEGORY', response.data.category)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  getPost(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('GET_POST', null)
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get("/info/show/" + data.slug)
        .then(response => {
          context.commit('GET_POST', response.data.post)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}