// import Vue from 'vue'
import guest from './middleware/guest'
import auth from './middleware/auth'
// Vue.component('Category', require('../components/book/Category.vue').default);
// import Category from '../components/book/Category.vue';



export default [{
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "Home" */ '../components/Home.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "Login" */ '../components/auth/Login.vue'),
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/social-dop-register/:id/:token',
    name: 'SocialDopRegister',
    component: () => import( /* webpackChunkName: "SocialDopRegister" */ '../components/auth/SocialDopRegister.vue'),
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import( /* webpackChunkName: "Register" */ '../components/auth/Register.vue'),
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import( /* webpackChunkName: "Logout" */ '../components/auth/Logout.vue'),
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/password/email',
    name: 'ForgotPassword',
    component: () => import( /* webpackChunkName: "ForgotPassword" */ '../components/auth/ForgotPassword.vue'),
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/password/reset',
    name: 'ResetPassword',
    component: () => import( /* webpackChunkName: "ResetPassword" */ '../components/auth/ResetPassword.vue'),
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/page/:slug',
    // component: Register,
    component: () => import( /* webpackChunkName: "Page" */ '../components/page/Page.vue'),
    name: 'Page',
    meta: {

    }
  },
  {
    path: '/cabinet/settings',
    component: () => import( /* webpackChunkName: "CabinetSettings" */ '../components/cabinet/Settings.vue'),
    name: 'CabinetSettings',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/settings/author',
    component: () => import( /* webpackChunkName: "CabinetSettingsAuthor" */ '../components/cabinet/SettingsAuthor.vue'),
    name: 'CabinetSettingsAuthor',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/settings/password',
    component: () => import( /* webpackChunkName: "CabinetSettingsPassword" */ '../components/cabinet/SettingsPassword.vue'),
    name: 'CabinetSettingsPassword',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/transactions',
    component: () => import( /* webpackChunkName: "CabinetTransactions" */ '../components/cabinet/Transactions.vue'),
    name: 'CabinetTransactions',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/commerce',
    component: () => import( /* webpackChunkName: "Commerce" */ '../components/cabinet/commerce/Index.vue'),
    name: 'Commerce',
    meta: {
      group: 'Commerce',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/commerce/documents',
    component: () => import( /* webpackChunkName: "CommerceDocuments" */ '../components/cabinet/commerce/Documents.vue'),
    name: 'CommerceDocuments',
    meta: {
      group: 'Commerce',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/commerce/documents/:slug',
    component: () => import( /* webpackChunkName: "CommerceDocument" */ '../components/cabinet/commerce/Document.vue'),
    name: 'CommerceDocument',
    meta: {
      group: 'Commerce',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/commerce/statement-document',
    component: () => import( /* webpackChunkName: "StatementDocument" */ '../components/cabinet/commerce/StatementDocument.vue'),
    name: 'StatementDocument',
    meta: {
      group: 'Commerce',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/commerce/document-history/:type/:id',
    component: () => import( /* webpackChunkName: "CommerceDocumentHistory" */ '../components/cabinet/commerce/Document.vue'),
    name: 'CommerceDocumentHistory',
    meta: {
      group: 'Commerce',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/commerce/contracts/detail/:slug',
    component: () => import( /* webpackChunkName: "ContractsDetail" */ '../components/cabinet/commerce/ContractsDetail.vue'),
    name: 'ContractsDetail',
    meta: {
      group: 'Commerce',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/commerce/sale/:id',
    component: () => import( /* webpackChunkName: "AppendixDetail" */ '../components/cabinet/commerce/AppendixDetail.vue'),
    name: 'AppendixDetail',
    meta: {
      group: 'Commerce',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/commerce/payment-details',
    component: () => import( /* webpackChunkName: "PaymentDetails" */ '../components/cabinet/commerce/PaymentDetails.vue'),
    name: 'PaymentDetails',
    meta: {
      group: 'Commerce',
      middleware: [
        auth
      ]
    }
  },
 
  // {
  //   path: '/cabinet/statistics/statistics',
  //   component: () => import( /* webpackChunkName: "CabinetStatistics" */ '../components/cabinet/statistics/StatisticsAll.vue'),
  //   name: 'CabinetStatistics',
  //   meta: {
  //     group: 'Cabinet',
  //     middleware: [
  //       auth
  //     ]
  //   }
  // },
  {
    path: '/cabinet/statistics',
    component: () => import( /* webpackChunkName: "CabinetStatisticsIndex" */ '../components/cabinet/statistics/Index.vue'),
    name: 'CabinetStatisticsIndex',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/statistics/day',
    component: () => import( /* webpackChunkName: "CabinetStatisticsDay" */ '../components/cabinet/statistics/StatisticsDay.vue'),
    name: 'CabinetStatisticsDay',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/statistics/views',
    component: () => import( /* webpackChunkName: "CabinetStatisticsViews" */ '../components/cabinet/statistics/StatisticsViews.vue'),
    name: 'CabinetStatisticsViews',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/statistics/views-period',
    component: () => import( /* webpackChunkName: "CabinetStatisticsViewsPeriod" */ '../components/cabinet/statistics/StatisticsViewsPeriod.vue'),
    name: 'CabinetStatisticsViewsPeriod',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/statistics/views-chapters',
    component: () => import( /* webpackChunkName: "CabinetStatisticsViewsByChapters" */ '../components/cabinet/statistics/StatisticsViewsChapters.vue'),
    name: 'CabinetStatisticsViewsByChapters',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/statistics-payouts',
    component: () => import( /* webpackChunkName: "CabinetStatisticsPayouts" */ '../components/cabinet/StatisticsPayouts.vue'),
    name: 'CabinetStatisticsPayouts',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/balance',
    component: () => import( /* webpackChunkName: "CabinetBalance" */ '../components/cabinet/Balance.vue'),
    name: 'CabinetBalance',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet',
    // component: CabinetIndex,
    component: () => import( /* webpackChunkName: "CabinetIndex" */ '../components/cabinet/Index.vue'),
    name: 'CabinetIndex',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    },
  },
  {
    path: '/cabinet/series',
    component: () => import( /* webpackChunkName: "CabinetSeries" */ '../components/cabinet/Series.vue'),
    name: 'CabinetSeries',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/serie/edit/:id',
    // component: CabinetSerieEdit,
    component: () => import( /* webpackChunkName: "CabinetSerieEdit" */ '../components/cabinet/SerieEdit.vue'),
    name: 'CabinetSerieEdit',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/serie/add',
    component: () => import( /* webpackChunkName: "CabinetSerieAdd" */ '../components/cabinet/SerieEdit.vue'),
    name: 'CabinetSerieAdd',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/loyalty-books',
    component: () => import( /* webpackChunkName: "MyBooksLoyalty" */ '../components/cabinet/MyBooksLoyalty.vue'),
    name: 'MyBooksLoyalty',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/books',
    component: () => import( /* webpackChunkName: "CabinetBooks" */ '../components/cabinet/Books.vue'),
    name: 'CabinetBooks',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/book/edit/:id',
    // component: CabinetBookEdit,
    component: () => import( /* webpackChunkName: "CabinetBookEdit" */ '../components/cabinet/BookEdit.vue'),
    name: 'CabinetBookEdit',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/book/add',
    component: () => import( /* webpackChunkName: "CabinetBookAdd" */ '../components/cabinet/BookEdit.vue'),
    name: 'CabinetBookAdd',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/chapter/edit/:id',
    component: () => import( /* webpackChunkName: "CabinetChapterEdit" */ '../components/cabinet/ChapterEdit.vue'),
    name: 'CabinetChapterEdit',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/chapter/add/:bookId',
    component: () => import( /* webpackChunkName: "CabinetChapterAdd" */ '../components/cabinet/ChapterEdit.vue'),
    name: 'CabinetChapterAdd',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/blogs',
    component: () => import( /* webpackChunkName: "CabinetBlogs" */ '../components/cabinet/Blogs.vue'),
    name: 'CabinetBlogs',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/blog/edit/:id',
    component: () => import( /* webpackChunkName: "CabinetBlogEdit" */ '../components/cabinet/BlogEdit.vue'),
    name: 'CabinetBlogEdit',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/cabinet/blog/add',
    component: () => import( /* webpackChunkName: "CabinetBlogAdd" */ '../components/cabinet/BlogEdit.vue'),
    name: 'CabinetBlogAdd',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },

  {
    path: '/category/:slug',
    component: () => import( /* webpackChunkName: "CategoryShow" */ '../components/book/Category.vue'),
    name: 'CategoryShow'
  },
  {
    path: '/tag/:slug',
    component: () => import( /* webpackChunkName: "TagShow" */ '../components/book/Category.vue'),
    name: 'TagShow'
  },
  {
    path: '/book/:slug',
    component: () => import( /* webpackChunkName: "BookShow" */ '../components/book/Book.vue'),
    name: 'BookShow'
  },
  {
    path: '/account/library',
    component: () => import( /* webpackChunkName: "AccountLibrary" */ '../components/account/Library.vue'),
    name: 'AccountLibrary',
    meta: {
      group: 'Account',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/account/purchases',
    component: () => import( /* webpackChunkName: "AccountPurchases" */ '../components/account/Purchases.vue'),
    name: 'AccountPurchases',
    meta: {
      group: 'Account',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/account/notifications',
    component: () => import( /* webpackChunkName: "AccountNotifications" */ '../components/account/Notifications.vue'),
    name: 'AccountNotifications',
    meta: {
      group: 'Account',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/user/:userName',
    component: () => import( /* webpackChunkName: "UserShow" */ '../components/account/User.vue'),
    name: 'UserShow',
    meta: {
      group: '',
    }
  },
  {
    path: '/reader/:bookSlug',
    component: () => import( /* webpackChunkName: "ReaderShow" */ '../components/book/ReaderShow.vue'),
    name: 'ReaderShow',
    meta: {
      group: 'Reader',

    }
  },
  {
    path: '/reader/:bookSlug/:id',
    component: () => import( /* webpackChunkName: "ReaderShowFull" */ '../components/book/ReaderShow.vue'),
    name: 'ReaderShowFull',
    meta: {
      group: 'Reader',

    }
  },
  {
    path: '/reader-speech/:bookSlug/:id',
    component: () => import( /* webpackChunkName: "ReaderSpeech" */ '../components/book/ReaderShowSpeech.vue'),
    name: 'ReaderShowSpeech',
    meta: {
      group: 'Reader',

    }
  },
  {
    path: '/search/:search',
    component: () => import( /* webpackChunkName: "Search" */ '../components/book/Search.vue'),
    name: 'Search',
    meta: {
      group: '',

    }
  },
  {
    path: '/support',
    component: () => import( /* webpackChunkName: "Support" */ '../components/account/Support.vue'),
    name: 'Support',
    meta: {
      group: '',

    }
  },
  {
    path: '/category-info/:slug',
    component: () => import( /* webpackChunkName: "CategoryInfoShow" */ '../components/info/Category.vue'),
    name: 'CategoryInfoShow'
  },
  {
    path: '/info/:slug',
    component: () => import( /* webpackChunkName: "InfoShow" */ '../components/info/Post.vue'),
    name: 'InfoShow'
  },
  {
    path: '/contest',
    component: () => import( /* webpackChunkName: "ContestAll" */ '../components/contest/Index.vue'),
    name: 'ContestAll',
    meta: {
      group: 'Contest',
    }
  },
  {
    path: '/contest/:slug',
    component: () => import( /* webpackChunkName: "ContestShow" */ '../components/contest/Contest.vue'),
    name: 'ContestShow',
    meta: {
      group: 'Contest',
    }
  },
  {
    path: '/contest-rules/:slug',
    component: () => import( /* webpackChunkName: "ContestRules" */ '../components/contest/ContestRules.vue'),
    name: 'ContestRules',
    meta: {
      group: 'Contest',
    }
  },
  {
    path: '/contest-add/:slug',
    component: () => import( /* webpackChunkName: "ContestAdd" */ '../components/contest/ContestAdd.vue'),
    name: 'ContestAdd',
    meta: {
      group: 'Contest',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/auth/:provider/callback',
    name: 'socialCallback',
    component: {
      template: '<div class="auth-component"></div>'
    }
  },
  {
    path: '/blog',
    component: () => import( /* webpackChunkName: "BlogAll" */ '../components/blog/Index.vue'),
    name: 'BlogAll',
    meta: {
      group: 'Blog',
    }
  },
  {
    path: '/blog/:slug',
    component: () => import( /* webpackChunkName: "BlogShow" */ '../components/blog/Blog.vue'),
    name: 'BlogShow',
    meta: {
      group: 'Blog',
    }
  },
  {
    path: '/partner',
    component: () => import( /* webpackChunkName: "PartnerIndex" */ '../components/partner/Index.vue'),
    name: 'PartnerIndex',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/partner/history',
    component: () => import( /* webpackChunkName: "PartnerHistory" */ '../components/partner/History.vue'),
    name: 'PartnerHistory',
    meta: {
      group: 'Cabinet',
      middleware: [
        auth
      ]
    }
  },
  
  // {
  //   path: '/partner/payout',
  //   component: () => import( /* webpackChunkName: "PartnerPayout" */ '../components/partner/Payout.vue'),
  //   name: 'PartnerPayout',
  //   meta: {
  //     group: 'Cabinet',
  //     middleware: [
  //       auth
  //     ]
  //   }
  // },
  {
    path: '/*',
    component: () => import( /* webpackChunkName: "NotFound" */ '../components/NotFound.vue'),
    name: 'NotFound',
   
  },
 







];