import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import {
  mdiHome,
  mdiMagnify,
  mdiBell,
  mdiSort,
  mdiDelete,
  mdiArrowLeft,
  mdiArrowRight,
  mdiClose,
  mdiFileUpload,
  mdiSquareEditOutline,
  mdiPlus,
  mdiEye,
  mdiEyeOff,
  mdiStar,
  mdiBookshelf,
  mdiDotsHorizontal,
  mdiPoll,
  mdiCommentTextOutline,
  mdiTextBoxOutline,
  mdiCog,
  mdiBrightness6,
  mdiFormatSize,
  mdiFormatLineSpacing,
  mdiFormatListBulleted,
  mdiBook,
  mdiBookOpenVariant,
  mdiBookOpenPageVariant,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiFolder,
  mdiFolderOpen,
  mdiHeart,
  mdiBookmark,
  mdiShareVariant,
  mdiCalendar,
  mdiDrag,
  mdiWallet,
  mdiWalletPlus,
  mdiAccountGroup,
  mdiAccount,
  mdiAccountSettings,
  mdiGmail,
  mdiLock,
  mdiLockPlus,
  mdiCheck,
  mdiCart,
  mdiHelpCircle,
  mdiTrophyAward,
  mdiClockAlertOutline,
  mdiFormatLetterCase,
  mdiPen,
  mdiFacebook,
  mdiGithub,
  mdiVk,
  mdiTelegram,
  mdiCloseCircle,
  mdiCheckCircle,
  mdiAlertCircle,
  mdiFileDocument,
  mdiFileDocumentOutline,
  mdiVolumeSource,
  mdiPurse,
  mdiTableClock,
  mdiInformation,
  mdiInformationOutline,
  mdiMenuDown,
  mdiMenuRight,
  mdiClockOutline,
  mdiPlayCircle,
  mdiPlay,
  mdiPauseCircle,
  mdiSale,
  mdiHeadphones,
} from '@mdi/js'
Vue.use(Vuetify);
import ru from 'vuetify/es5/locale/ru'; //Перевод для vuetify
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#403042', 
      },
    },
  },
  lang: {
    locales: {
      ru
    },
    current: 'ru',

  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      home: mdiHome,
      bell: mdiBell,
      magnify: mdiMagnify,
      sort: mdiSort,
      delete: mdiDelete,
      arrow_left: mdiArrowLeft,
      arrow_right: mdiArrowRight,
      close: mdiClose,
      file_upload: mdiFileUpload,
      square_edit_outline: mdiSquareEditOutline,
      drag: mdiDrag,
      plus: mdiPlus,
      eye: mdiEye,
      eye_off: mdiEyeOff,
      star: mdiStar,
      bookshelf: mdiBookshelf,
      dots_horizontal: mdiDotsHorizontal,
      poll: mdiPoll,
      comment_text_outline: mdiCommentTextOutline,
      text_box_outline: mdiTextBoxOutline,
      cog: mdiCog,
      brightness_6: mdiBrightness6,
      format_size: mdiFormatSize,
      format_line_spacing: mdiFormatLineSpacing,
      format_list_bulleted: mdiFormatListBulleted,
      book: mdiBook,
      book_open_variant: mdiBookOpenVariant,
      book_open_page_variant: mdiBookOpenPageVariant,
      fullscreen: mdiFullscreen,
      fullscreen_exit: mdiFullscreenExit,
      folder: mdiFolder,
      folder_open: mdiFolderOpen,
      heart: mdiHeart,
      bookmark: mdiBookmark,
      share_variant: mdiShareVariant,
      calendar: mdiCalendar,
      wallet: mdiWallet,
      wallet_plus: mdiWalletPlus,
      account_group: mdiAccountGroup,
      account: mdiAccount,
      account_settings: mdiAccountSettings,
      gmail: mdiGmail,
      lock: mdiLock,
      lock_plus: mdiLockPlus,
      check: mdiCheck,
      cart: mdiCart,
      help_circle: mdiHelpCircle,
      trophy_award: mdiTrophyAward,
      clock_alert_outline: mdiClockAlertOutline,
      format_letter_case: mdiFormatLetterCase,
      facebook: mdiFacebook,
      github: mdiGithub,
      vk: mdiVk,
      tg: mdiTelegram,
      pen: mdiPen,
      close_circle: mdiCloseCircle,
      check_circle: mdiCheckCircle,
      alert_circle: mdiAlertCircle,
      file_document: mdiFileDocument,
      file_document_outline: mdiFileDocumentOutline,
      volume_source: mdiVolumeSource,
      purse: mdiPurse,
      table_clock: mdiTableClock,
      information: mdiInformation,
      information_outline: mdiInformationOutline,
      menu_down: mdiMenuDown,
      menu_right: mdiMenuRight,
      clock_outline : mdiClockOutline,
      play_circle : mdiPlayCircle,
      pause_circle : mdiPauseCircle,
      play : mdiPlay,
      sale : mdiSale,
      headphones : mdiHeadphones,
      // support: 'mdi-lifebuoy',
      // steam: 'mdi-steam-box',
      // pc: 'mdi-desktop-classic',
      // xbox: 'mdi-xbox',
      // playstation: 'mdi-playstation',
      // switch: 'mdi-nintendo-switch',
    },
  },
});