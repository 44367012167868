import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta'
Vue.use(VueMeta);

// import VueTextToSpeech from 'vue-text-to-speech';
// Vue.use(VueTextToSpeech);

//Отрубили ютуб, сайт глючит
import VueYouTubeEmbed from 'vue-youtube-embed'
Vue.use(VueYouTubeEmbed)


// import '@mdi/font/css/materialdesignicons.css'
// import fullscreen from 'vue-fullscreen'
// Vue.use(fullscreen)
// import quill from '@/plugins/quill'
// Vue.use(quill);
// import './plugins/quill';
import AppFooter from './components/Footer';
import snackbarError from './components/snackbarError';
import pageLoader from './components/pageLoader';
import NoContent from './components/NoContent';
import CommentList from './components/comment/CommentList';
import CommentCard from './components/comment/CommentCard';
Vue.component('app-footer', AppFooter)
Vue.component('snackbarError', snackbarError);
Vue.component('pageLoader', pageLoader);
Vue.component('NoContent', NoContent);
Vue.component('CommentList', CommentList);
Vue.component('CommentCard', CommentCard);
// import FileSaver from "file-saver";
// Vue.use(FileSaver);
// import PhoneNumberInput from 'vue-phone-number-input';
// import 'vue-phone-number-input/dist/vue-phone-number-input.css';

// Vue.component('vue-phone-number-input', PhoneNumberInput);
import './styles/app.scss'
Vue.directive('phone', {
  bind(el) {  
    el.oninput = function(e) {
      if (!e.isTrusted) {
        return;
      }

      const x = this.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
      el.dispatchEvent(new Event('input'));
    }
  },
});
// window.eventBus = new Vue();
Vue.prototype.$eventBus = new Vue();
import axios from 'axios';
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios);

// import VueSocialauth from 'vue-social-auth'
import VueSocialauth from 'vue-social-auth';
Vue.use(VueSocialauth, {
  tokenName: 'token_social',
  // storageType: 'cookieStorage',
  storageNamespace: 'socialstorage',//Если не указать сносит наш токен
  providers: {
    github: {
      clientId: process.env.VUE_APP_GITHUB_ID,
      redirectUri: process.env.VUE_APP_URL+'/auth/github/callback' // Your client app URL
    },
    facebook: {
      clientId: process.env.VUE_APP_FACEBOOK_ID,
      redirectUri: process.env.VUE_APP_URL+'/auth/facebook/callback' // Your client app URL
    },
    vkontakte: {
      clientId: process.env.VUE_APP_VKONTAKTE_ID,
      redirectUri: process.env.VUE_APP_URL+'/auth/vkontakte/callback' // Your client app URL
    }
  }
})

window.Pusher = require('pusher-js');
//   window.Echo = new Echo({
    //   broadcaster: 'pusher',
    //   key: 123,
    //   // wsHost: window.location.hostname,
    //   wsHost: process.env.VUE_APP_WSS_URL,
    //   wsPort: 6001,
    //   authEndpoint:process.env.VUE_APP_API_URL+'/broadcasting/auth',
    //   auth: {
    //     headers: {
    //       Authorization: 'Bearer '+this.token
    //     }
    //   },
    //   authTransport: 'jsonp',
    //   httpHost: process.env.VUE_APP_WSS_URL,
    //   httpPath: '',
    //   disableStats: true,
    //   encrypted: false,
    //   useTLS: false,
    //   forceTLS: false,
    //   enabledTransports: ['ws','wss'], // <- added this param,

    // });




// Vue.axios.defaults.baseURL = 'http://api-bookstab.loc/api'
// Vue.axios.defaults.baseURL = 'http://api.bookstab.ru/api'
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Vue.config.productionTip = false;

import VueYandexMetrika from 'vue-yandex-metrika';

Vue.use(VueYandexMetrika, {
  id: process.env.VUE_APP_YACOUNTER,
  router: router,
  env: process.env.NODE_ENV
  // env: 'production'
  // other options
});


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  data: function () {
    return {
      currentUser: null,
      gcKey: process.env.VUE_APP_GR_SITE_KEY,
    };
  },
  methods:{
    showAuthMessage(){
      this.$eventBus.$emit("show-modal-error", [true, {message: "Вы должны авторизоваться"}, "error"]);
    }
  }
}).$mount('#app')