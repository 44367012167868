<template>
  <div>
    <v-snackbar
      v-model="showSnackbarMessage"
      :color="colorMessage"
      :timeout="5000"
      top
    >
      <div class="snackbarMessage">{{ message }}</div>

      <!-- <v-btn dark icon @click="showSnackbarMessage = false">
        <v-icon dark>$vuetify.icons.close</v-icon>
      </v-btn> -->
      <template v-slot:action="{ attrs }">
        <v-btn dark icon @click="showSnackbarMessage = false" v-bind="attrs">
          <v-icon dark>$vuetify.icons.close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="showSnackbar"
      :color="colorMessage"
      :timeout="5000"
      vertical
      top
    >
      <ul>
        <li v-for="(item, index) in listItems" :key="index">{{ item }}</li>
      </ul>
      <!-- <v-btn dark icon @click="showSnackbar = false">
        <v-icon dark>$vuetify.icons.close</v-icon>
      </v-btn> -->
      <template v-slot:action="{ attrs }">
        <v-btn dark icon @click="showSnackbar = false" v-bind="attrs">
          <v-icon dark>$vuetify.icons.close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  //data: () => ({}),
  data: function () {
    return {
      showSnackbar: false,
      showSnackbarMessage: false,
      listItems: [],
      message: "",
      type: "message",
      colorMessage: "info",
    };
  },
  methods: {
    sortList(list) {
      // console.log('list');
      // console.log(list);
      //this.type = list[2];
      this.colorMessage = list[2];
      this.listItems = [];
      //если есть ошибки, то сначала они
      if (list[1].errors) {
        var obj = list[1].errors;
        if (Object.keys(obj).length == 0) return;
        var arr = [];
        // var app = this;
        for (var key in obj) {
          obj[key].forEach(function (item) {
            arr.push(item);
          });
        }
        if (arr.length == 0) return;

        this.listItems = arr;
        this.type = "error";
        this.showSnackbar = list[0];
      } else if (list[1].message) {
        this.message = list[1].message;
        this.showSnackbarMessage = list[0];
      }
    },
  },
  created() {
    this.$eventBus.$on("show-modal-error", this.sortList);
  },

  beforeDestroy() {
    this.$eventBus.$off("show-modal-error");
  },
};
</script>
