import Vue from 'vue'
const state = {
  contests: [],
  contest: null,
  books: [],
  mybooks: [],
  current_user_books: [],
  category: null,
  likes: [],
  libraries: [],
  contestSort: [],
  comments: [],
  comment_page: 1,
  comment_pages: 0,

}
const getters = {
  contest(state) {
    return state.contest;
  },
  contests(state) {
    return state.contests;
  },
  category(state) {
    return state.category;
  },
  contestSort(state) {
    return state.contestSort;
  },
  books(state) {
    return state.books;
  },
  mybooks(state) {
    return state.mybooks;
  },
  current_user_books(state) {
    console.log('current_user_books',state.current_user_books)
    console.log('current_user_books',state.current_user_books.length)
    return state.current_user_books;
  },
  comments(state) {
    return state.comments;
  },
  comment_page(state) {
    return state.comment_page;
  },
  comment_pages(state) {
    return state.comment_pages;
  },

}
const mutations = {
  GET_CONTESTS(state, contests) {
    state.contests = contests;
  },
  GET_CONTEST(state, contest) {
    state.contest = contest;
  },
  GET_CATEGORY(state, category) {
    state.category = category;
  },
  GET_CONTEST_SORT(state, contestSort) {
    state.contestSort = contestSort;
  },
  GET_BOOKS(state, books) {
    state.books = books;
  },
  GET_MYBOOKS(state, mybooks) {
    state.mybooks = mybooks;
  },
  CURRENT_USER_BOOKS(state, current_user_books) {
    state.current_user_books = current_user_books;
  },
  GET_COMMENTS(state, comments) {
    // console.log(books)
    state.comments = comments;
  },
  SET_COMMENT_PAGE(state, page) {
    state.comment_page = page;
  },
  SET_COMMENT_PAGES(state, pages) {
    state.comment_pages = pages;
  },

}



const actions = {


  getContests(context, data) {
    let path = '/contest';
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    // context.commit('GET_BOOKS', [])
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get(path +
          "?cat=" + data.sort +
          "&page=" +
          data.page)
        .then(response => {

          context.commit('GET_CONTESTS', response.data.contests.data)
          context.commit('GET_CATEGORY', response.data.category)
          context.commit('GET_CONTEST_SORT', response.data.contestSort)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  getContest(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    // context.commit('GET_CONTEST', null)
    // context.commit('GET_BOOKS', [])
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get("/contest/" +
          data.slug +
          "?page=" +
          data.page +
          "&sorting=" +
          data.sort)

        .then(response => {
          context.commit('GET_CONTEST', response.data.contest)
          context.commit('GET_BOOKS', response.data.books.data)
          context.dispatch('pageIsLoading', false, {
            root: true
          })

          if (response.data.comments) {
            context.commit('comments/SET_COMMENTS', response.data.comments.data, {
              root: true
            })
            context.commit('comments/SET_PAGE', data.page, {
              root: true
            })
            context.commit('comments/SET_PAGES', response.data.comments.last_page, {
              root: true
            })
          }
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  getContestAdd(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    // context.commit('GET_CONTEST', null)
    // context.commit('GET_BOOKS', [])
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get("/contest/" + data.slug + "/show-add")
        .then(response => {
          context.commit('GET_CONTEST', response.data.contest)
          context.commit('GET_MYBOOKS', response.data.mybooks)
          context.commit('CURRENT_USER_BOOKS', response.data.current_user_books)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  bookAdd(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/contest/" + data.contest_id + '/book-add', {
          // book_id: 11
          book_id: data.book_id
        })
        .then(response => {
          context.commit('CURRENT_USER_BOOKS', response.data.current_user_books)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },
  deleteBook(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post("/contest/" + data.contest_id + '/book-dell', {
          book_id: data.book_id
        })
        .then(response => {
          context.commit('CURRENT_USER_BOOKS', response.data.current_user_books)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })


  },

}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}