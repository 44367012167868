<template>
  <div class="comments-list" :class="child  ? 'children' : ''">
    <comment-card
      v-for="(comment, index) in comments"
      :key="comment.id"
      :index="index"
      :comment="comment"
      :entity_user_id="entity_user_id"
      :commentable_type="commentable_type"
    ></comment-card>
  </div>
</template>
<script>
export default {
  name: "CommentList",
  props: ["comments", "child", "entity_user_id", "commentable_type"],
  methods: {},
};
</script>
