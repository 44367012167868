import Vue from 'vue'
const state = {
  transactions: [],
  payouts: [],
  books: [],
}
const getters = {
  transactions(state) {
    return state.transactions;
  },
  payouts(state) {
    return state.payouts;
  },
  books(state) {
    return state.books;
  },
}
const mutations = {
  SET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions;
  },
  SET_PAYOUTS(state, payouts) {
    state.payouts = payouts;
  },
  SET_BOOKS(state, books) {
    state.books = books;
  },

}



const actions = {


  getTransactions(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('SET_TRANSACTIONS', [])
    return new Promise((resolve, reject) => {
      Vue.axios.get(
        "/partner-get-transactions" +
          "?page=" +
          data.page +
          "&dates=" +
          data.dates
        )
        .then(response => {
          context.commit('SET_TRANSACTIONS', response.data.transactions.data)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },


  getPayouts(context) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('SET_PAYOUTS', [])
    return new Promise((resolve, reject) => {
      Vue.axios.get("/partner-get-payouts")
        .then(response => {
          context.commit('SET_PAYOUTS', response.data.payouts)
          if(response.data.books){
            context.commit('SET_BOOKS', response.data.books)
          }
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
  balanceMinus(context, data) {
      return new Promise((resolve, reject) => {
      Vue.axios.post("/partner-add-payout", {
        summ: data.summ
      })
        .then(response => {
          context.commit('SET_PAYOUTS', response.data.payouts)
          context.dispatch('setUser', response.data.user, { root: true })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
 



}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}