import Vue from 'vue'
const state = {
  blogs: [],
  blog: null,
  libraries: [],
  likes: [],


}
const getters = {
  blog(state) {
    return state.blog;
  },
  blogs(state) {
    return state.blogs;
  },
  likes(state) {
    return state.likes;
  },
  libraries(state) {
    return state.libraries;
  },
  blogSort(state) {
    return state.blogSort;
  },
  comments(state) {
    return state.comments;
  },
  comment_page(state) {
    return state.comment_page;
  },
  comment_pages(state) {
    return state.comment_pages;
  },
}
const mutations = {
  SET_BLOGS(state, blogs) {
    state.blogs = blogs;
  },

  SET_BLOG(state, blog) {
    state.blog = blog;
  },
  ADD_COMMENTS_PLUS(state) {
    state.blog.count_comments++;
  },
  ADD_LIKES_PLUS(state) {
    state.blog.count_likes++;
  },
  ADD_LIKES_MINUS(state) {
    state.blog.count_likes--;
  },
  ADD_LIBRARIES(state, libraries) {
    state.libraries = libraries;
  },
  ADD_LIBRARIES_PLUS(state) {
    state.blog.count_libraries++;
  },
  ADD_LIBRARIES_MINUS(state) {
    state.blog.count_libraries--;
  },
}



const actions = {


  getBlogs(context, data) {

    context.dispatch('pageIsLoading', true, {
      root: true
    })
    return new Promise((resolve, reject) => {
      Vue.axios.get('/blog' +
          "?page=" +
          data.page +
          "&sorting=" +
          data.sort)
        .then(response => {
          context.commit('SET_BLOGS', response.data.blogs.data)
          context.dispatch('pageIsLoading', false, {
            root: true
          })

          if(response.data.banners){
            context.commit('SET_BANNERS', response.data.banners, {
            root: true
          })
          }
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
  getBlog(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    context.commit('SET_BLOG', null)
    return new Promise((resolve, reject) => {
      let path = "/get-blog/" +
        data.blogSlug +
        "?page=" +
        data.page +
        "&sorting=" +
        data.sort;

      Vue.axios.get(path)
        .then(response => {

          context.commit('SET_BLOG', response.data.blog)

          context.commit('likes/ADD_LIKES_IDS', response.data.blog.likes_ids, {
            root: true
          })
          context.commit('likes/ADD_LIKES_COUNT', response.data.blog.count_likes, {
            root: true
          })
          context.commit('libraries/ADD_LIBRARIES_IDS', response.data.blog.libraries_ids, {
            root: true
          })
          context.commit('libraries/ADD_LIBRARIES_COUNT', response.data.blog.count_libraries, {
            root: true
          })
          // context.commit('ADD_LIBRARIES', response.data.blog.libraries_ids)
          context.commit('comments/SET_COMMENTS', response.data.comments.data, {
            root: true
          })
          context.commit('comments/SET_PAGE', data.page, {
            root: true
          })
          context.commit('comments/SET_PAGES', response.data.comments.last_page, {
            root: true
          })
          context.commit('comments/SET_SORT', data.sort, {
            root: true
          })

          context.dispatch('pageIsLoading', false, {
            root: true
          })

          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })


  },
  // addLibrary(context, data) {
  //   return new Promise((resolve, reject) => {
  //     Vue.axios.get("/add-library/" + data.bookId)
  //       .then(response => {
  //         response.data.attached ? context.commit('ADD_LIBRARIES_PLUS') : context.commit('ADD_LIBRARIES_MINUS');
  //         context.commit('ADD_LIBRARIES', response.data.libraries_ids)
  //         resolve(response)
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  // },
  // addLike(context, data) {
  //   return new Promise((resolve, reject) => {
  //     Vue.axios.get("/add-like/" + data.bookId)
  //       .then(response => {
  //         response.data.attached ? context.commit('ADD_LIKES_PLUS') : context.commit('ADD_LIKES_MINUS');
  //         context.commit('ADD_LIKES', response.data.likes_ids)
  //         resolve(response)
  //       })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  // },
  // getBooksSearch(context, data) {
  //   context.dispatch('pageIsLoading', true, {
  //     root: true
  //   })
  //   // context.commit('GET_BOOKS', [])
  //   // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
  //   return new Promise((resolve, reject) => {
  //     Vue.axios.get('search/' +
  //         data.search +
  //         "?page=" +
  //         data.page)
  //       .then(response => {

  //         context.commit('GET_BOOKS', response.data.books.data)
  //         context.dispatch('pageIsLoading', false, {
  //           root: true
  //         })
  //         resolve(response)
  //       })
  //       .catch(error => {
  //         context.dispatch('pageIsLoading', false, {
  //           root: true
  //         })
  //         reject(error)
  //       })
  //   })


  // },


}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}