import Vue from 'vue'
const state = {
  token: localStorage.getItem('access_token') || null,
}
const getters = {
  loggedIn(state) {
    return state.token !== null && state.token != 'undefined'
  },
  token(state) {
    return state.token;
  },
}
const mutations = {
  RETRIEVE_TOKEN(state, token) {
    // console.log('Записываем токен')
    localStorage.setItem('access_token', token);
    state.token = token;
  },
  DESTROY_TOKEN(state) {
    state.token = null
  },
}



const actions = {
  register(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/register', {
          name: data.name,
          email: data.email,
          password: data.password,
          birthday_date: data.birthday_date,
          captcha: data.captcha,
          partner_id : +localStorage.getItem("partner_id") || null
        })
        .then(response => {
          
          if (response.data.token) {
            const token = response.data.token
            context.commit('RETRIEVE_TOKEN', token)
            context.commit('SET_USER', response.data.user);
            Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  destroyToken(context) {
    if (context.getters.loggedIn) {
      localStorage.removeItem('access_token')
      context.commit('DESTROY_TOKEN')
    }
  },
  logout(context) {

    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    Vue.axios.post('/logout', {}, {
      headers: {
        Authorization: 'Bearer ' + context.state.token
      }
    });
    context.dispatch('destroyToken');
    context.dispatch('setUser', null, {
      root: true
    })
    // Vue.axios.defaults.headers.common['Authorization'] = '';
    // await Vue.axios.post('/logout');


    // console.log('тут запустим выход асинхронно');
  },
  retrieveToken(context, data) {

    return new Promise((resolve, reject) => {
      Vue.axios.post('/login', {
          email: data.email,
          password: data.password,
          captcha: data.captcha,
        })
        .then(response => {
          if (response.data.token) {
            const token = response.data.token
            context.commit('RETRIEVE_TOKEN', token)
            context.commit('SET_USER', response.data.user);
            Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          }
          resolve(response)
          // console.log(response);
          // context.commit('addPost', response.data)
        })
        .catch(error => {
          // console.log(error)
          // window.eventBus.$emit("show-modal-error", [true, {message: 'Ошибка авторизации'}, "error"]);
          reject(error)
        })
    })
  },
  forgotPassword(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/password/email', {
          email: data.email,
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  resetPassword(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.post('/password/reset', {
          email: data.email,
          password: data.password,
          password_confirmation: data.password_confirmation,
          token: data.token,
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  socialLogin(context, data) {

    return new Promise((resolve, reject) => {
      Vue.axios.post('/sociallogin/' + data.provider, data.response)
        .then(response => {
          if (response.data.token) {
            const token = response.data.token
            context.commit('RETRIEVE_TOKEN', token)
            context.commit('SET_USER', response.data.user);
            Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          }
          resolve(response)
        })
        .catch(error => {
          // console.log(error)
          // window.eventBus.$emit("show-modal-error", [true, {message: 'Ошибка авторизации'}, "error"]);
          reject(error)
        })
    })
  },
  socialRegister(context, data) {

    return new Promise((resolve, reject) => {
      Vue.axios.post('/socialregister/' + data.id, {
          token: data.token,
          name: data.name,
          email: data.email,
          birthday_date: data.birthday_date,
          partner_id : +localStorage.getItem("partner_id") || null
        })
        .then(response => {
          if (response.data.token) {
            const token = response.data.token
            context.commit('RETRIEVE_TOKEN', token)
            context.commit('SET_USER', response.data.user);
            Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getSocData(context, data) {

    return new Promise((resolve, reject) => {
      Vue.axios.post('/social-data/' + data.id, {
          token: data.token,
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default {
  // namespaced: true,
  state,
  mutations,
  getters,
  actions
}