<template>
  <div class="not-content" v-if="!loadPage">
    <div class="title">{{title}}</div>
    <v-btn  v-if="routeName" :to="{name: routeName, params: routeParams, query: routeQuery}">{{btn}}</v-btn>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["routeName", "title","routeParams","buttonName", "routeQuery"], 
  data() {
    return {
        btn:this.buttonName
    };
  },
  methods: {},
  computed: {
    ...mapGetters(["loadPage"])
  },
  mounted(){
      //console.log(this.routeParams);
      if(!this.btn){
          this.btn ='Добавить'
      }
  }
};
</script>
