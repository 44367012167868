import Vue from 'vue'
const state = {

  libraries: [],
  books: [],
  blogs: [],
  librariesIds: [],
  librariesCount: 0,
  page: 1,
  pages: 0,
  content_loading: false,
  libcats: [],
}
const getters = {

  libraries(state) {
    return state.libraries;
  },
  books(state) {
    return state.books;
  },
  blogs(state) {
    return state.blogs;
  },
  page(state) {
    return state.page;
  },
  pages(state) {
    return state.pages;
  },
  content_loading(state) {
    return state.content_loading;
  },
  librariesIds(state) {
    return state.librariesIds;
  },
  librariesCount(state) {
    return state.librariesCount;
  },
  libcats(state) {
    return state.libcats;
  }

}
const mutations = {

  ADD_LIBRARIES(state, libraries) {
    state.libraries = libraries;
  },
  ADD_LIBRARIES_IDS(state, librariesIds) {
    state.librariesIds = librariesIds;
  },
  ADD_LIBRARIES_COUNT(state, count) {
    state.librariesCount = count;
  },
  ADD_BOOKS(state, books) {
    state.books = books;
  },
  ADD_BLOGS(state, blogs) {
    state.blogs = blogs;
  },
  SET_PAGE(state, page) {
    state.page = page;
  },
  SET_PAGES(state, pages) {
    state.pages = pages;
  },
  CONTENT_LOADING(state, status) {
    state.content_loading = status;
  },
  SET_LIBCATS(state, libcats) {
    state.libcats = libcats;
  }


}



const actions = {

  toggleLibrary(context, data) {

    let sendData = {};

    if (data.libcatId) {
      sendData.libcatId = data.libcatId;
    }

    if (data.getContent) {
      sendData.getContent = data.getContent;
    }

    if (data.page) {
      sendData.page = data.page;
    }
    if (data.sort) {
      sendData.sorting = data.sort;
    }

    return new Promise((resolve, reject) => {
      Vue.axios.post("/toggle-library/" + data.tableId + "/" + data.tableType, sendData)
        .then(response => {

          if (response.data.libraries_ids) {
            context.commit('ADD_LIBRARIES_COUNT', response.data.libraries_ids.length)
            context.commit('ADD_LIBRARIES_IDS', response.data.libraries_ids)
          }

          if (response.data.libcats) {
            context.commit('SET_LIBCATS', response.data.libcats);
          }

          //Если мы запрашивали контент, то обновляем его
          if (response.data.content) {
            if (data.tableType == 'book') {
              context.commit('ADD_BOOKS', response.data.content.data)
            } else if (data.tableType == 'blog') {
              context.commit('ADD_BLOGS', response.data.content.data);
            }

            context.commit('SET_PAGE', response.data.content.current_page)
            context.commit('SET_PAGES', response.data.content.last_page)
          }


          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },



  detachLibrary(context, data) {
    let sendData = {};

    if (data.libcatId) {
      sendData.libcatId = data.libcatId;
    }

    if (data.getContent) {
      sendData.getContent = data.getContent;
    }

    if (data.page) {
      sendData.page = data.page;
    }
    if (data.sort) {
      sendData.sorting = data.sort;
    }


    return new Promise((resolve, reject) => {
      Vue.axios.post("/detach-library/" + data.tableId + "/" + data.tableType, sendData)
      
      // Vue.axios.post("/account/library/" + data.deleteData[0] + "/" + data.deleteData[1] +
      //   "?page=" +
      //   data.page +
      //   "&sorting=" +
      //   data.sort)
        .then(response => {

          if (response.data.libraries_ids) {
            context.commit('ADD_LIBRARIES_COUNT', response.data.libraries_ids.length)
            context.commit('ADD_LIBRARIES_IDS', response.data.libraries_ids)
          }

          if (response.data.libcats) {
            context.commit('SET_LIBCATS', response.data.libcats);
          }

          //Если мы запрашивали контент, то обновляем его
          if (response.data.content) {
            if (data.tableType == 'book') {
              context.commit('ADD_BOOKS', response.data.content.data)
            } else if (data.tableType == 'blog') {
              context.commit('ADD_BLOGS', response.data.content.data);
            }

            context.commit('SET_PAGE', response.data.content.current_page)
            context.commit('SET_PAGES', response.data.content.last_page)
          }

          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  moveLibrary(context, data) {
    let sendData = {};

    if (data.libcatId) {
      sendData.libcatId = data.libcatId;
    }

    if (data.moveLibcatId) {
      sendData.moveLibcatId = data.moveLibcatId;
    }

    if (data.getContent) {
      sendData.getContent = data.getContent;
    }

    if (data.page) {
      sendData.page = data.page;
    }
    if (data.sort) {
      sendData.sorting = data.sort;
    }


    return new Promise((resolve, reject) => {
      Vue.axios.post("/move-library/" + data.tableId + "/" + data.tableType, sendData)
        .then(response => {

          if (response.data.libraries_ids) {
            context.commit('ADD_LIBRARIES_COUNT', response.data.libraries_ids.length)
            context.commit('ADD_LIBRARIES_IDS', response.data.libraries_ids)
          }

          if (response.data.libcats) {
            context.commit('SET_LIBCATS', response.data.libcats);
          }

          //Если мы запрашивали контент, то обновляем его
          if (response.data.content) {
            if (data.tableType == 'book') {
              context.commit('ADD_BOOKS', response.data.content.data)
            } else if (data.tableType == 'blog') {
              context.commit('ADD_BLOGS', response.data.content.data);
            }

            context.commit('SET_PAGE', response.data.content.current_page)
            context.commit('SET_PAGES', response.data.content.last_page)
          }

          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  getLibraries(context, data) {
    context.commit('CONTENT_LOADING', true)
    return new Promise((resolve, reject) => {
      Vue.axios.get(
        "/account/get-library/" + data.type +
        "?page=" +
        data.page +
        "&sorting=" +
        data.sort +
        "&libcatId=" +
        data.libcatId
      )
        .then(response => {
          // context.commit('ADD_LIBRARIES', response.data.libraries.data)

          if (response.data.type == 'book') {
            context.commit('ADD_BOOKS', response.data.content.data)
          } else if (response.data.type == 'blog') {
            context.commit('ADD_BLOGS', response.data.content.data);
          }

          context.commit('SET_PAGE', response.data.content.current_page)
          context.commit('SET_PAGES', response.data.content.last_page)

          context.commit('CONTENT_LOADING', false)

          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },


}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}