import Vue from 'vue'
const state = {
  user: null,
  books: [],
  series: [],
  blogs: [],
  comments: [],
  subscriptions: [],
  subscribers: [],
  subscribersCount: 0,
  page: 1,
  pages: 0,
  content_loading: false,
}
const getters = {
  user(state) {
    return state.user;
  },
  books(state) {
    return state.books;
  },
  series(state) {
    return state.series;
  },
  comments(state) {
    return state.comments;
  },
  blogs(state) {
    return state.blogs;
  },
  subscriptions(state) {
    return state.subscriptions;
  },
  subscribers(state) {
    return state.subscribers;
  },
  subscribersCount(state) {
    return state.subscribersCount;
  },
  page(state) {
    return state.page;
  },
  pages(state) {
    return state.pages;
  },
  content_loading(state) {
    return state.content_loading;
  },
}
const mutations = {
  GET_BOOKS(state, books) {
    state.books = books;
  },
  GET_SERIES(state, series) {
    state.series = series;
  },
  SET_COMMENTS(state, comments) {
    state.comments = comments;
  },
  SET_BLOGS(state, blogs) {
    state.blogs = blogs;
  },
  SET_SUBSCRIPTIONS(state, subscriptions) {
    state.subscriptions = subscriptions;
  },
  GET_USER(state, user) {
    state.user = user;
  },
  ADD_SUBSCRIBERS(state, subscribers) {
    state.subscribers = subscribers;
  },
  ADD_SUBSCRIBERS_COUNT(state, count) {
    state.subscribersCount = count;
  },
  SET_PAGE(state, page) {
    state.page = page;
  },
  SET_PAGES(state, pages) {
    state.pages = pages;
  },
  CONTENT_LOADING(state, status) {
    state.content_loading = status;
  },
  

}



const actions = {


  getUser(context, data) {
    context.dispatch('pageIsLoading', true, {
      root: true
    })
    // context.commit('GET_USER', [])
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get("/get-user-info/" + data.user_name + "?sorting=" + data.sort)
        .then(response => {
          
          context.commit('GET_USER', response.data.user)
          
          context.commit('ADD_SUBSCRIBERS', response.data.user.subscribers_ids)
          
          context.commit('ADD_SUBSCRIBERS_COUNT', response.data.user.subscribers_ids.length)
          
         

          if (response.data.type == 'books') {
            context.commit('GET_BOOKS', response.data.content.data)
            context.commit('SET_PAGE', response.data.content.current_page)
            context.commit('SET_PAGES', response.data.content.last_page)

          } else if (response.data.type == 'series') {
            context.commit('GET_SERIES', response.data.content)
            context.commit('SET_PAGE', 1)
            context.commit('SET_PAGES', 0)

          } else if (response.data.type == 'comments') {
            context.commit('SET_COMMENTS', response.data.content.data)
            context.commit('SET_PAGE', response.data.content.current_page)
            context.commit('SET_PAGES', response.data.content.last_page)

          } else if (response.data.type == 'blogs') {
            context.commit('SET_BLOGS', response.data.content.data);
            context.commit('SET_PAGE', response.data.content.current_page)
            context.commit('SET_PAGES', response.data.content.last_page)
          } else if (response.data.type == 'subscriptions') {
            context.commit('SET_SUBSCRIPTIONS', response.data.content.data);
            context.commit('SET_PAGE', response.data.content.current_page)
            context.commit('SET_PAGES', response.data.content.last_page)
          }



          // context.commit('GET_SERIES', response.data.series)
          // context.commit('SET_COMMENTS', response.data.comments.data)
          // context.commit('SET_BLOGS', response.data.blogs.data)
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },
  getContent(context, data) {
    context.commit('CONTENT_LOADING', true)
    // context.commit('GET_USER', [])
    // Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token
    return new Promise((resolve, reject) => {
      Vue.axios.get("/get-user-content/" + data.user_id + "/" + data.type +
          "?page=" + data.page +
          "&sorting=" + data.sort)
        .then(response => {
          if (response.data.type == 'book') {
            context.commit('GET_BOOKS', response.data.content.data)
            context.commit('SET_PAGE', response.data.content.current_page)
            context.commit('SET_PAGES', response.data.content.last_page)

          } else if (response.data.type == 'serie') {
            context.commit('GET_SERIES', response.data.content)
            context.commit('SET_PAGE', 1)
            context.commit('SET_PAGES', 0)

          } else if (response.data.type == 'comment') {
            context.commit('SET_COMMENTS', response.data.content.data)
            context.commit('SET_PAGE', response.data.content.current_page)
            context.commit('SET_PAGES', response.data.content.last_page)

          } else if (response.data.type == 'blog') {
            context.commit('SET_BLOGS', response.data.content.data);
            context.commit('SET_PAGE', response.data.content.current_page)
            context.commit('SET_PAGES', response.data.content.last_page)
          }else if (response.data.type == 'subscriptions') {
            context.commit('SET_SUBSCRIPTIONS', response.data.content.data);
            context.commit('SET_PAGE', response.data.content.current_page)
            context.commit('SET_PAGES', response.data.content.last_page)
          }



          // context.commit('GET_SERIES', response.data.series)
          // context.commit('SET_COMMENTS', response.data.comments.data)
          // context.commit('SET_BLOGS', response.data.blogs.data)
          context.commit('CONTENT_LOADING', false)
          resolve(response)

        })
        .catch(error => {
          context.commit('CONTENT_LOADING', false)
          reject(error)
        })
    })
  },
  subscribeUser(context, data) {
    return new Promise((resolve, reject) => {
      Vue.axios.get("/subscribe-user/" + data.user_id)
        .then(response => {
          context.commit('ADD_SUBSCRIBERS_COUNT', response.data.subscribers_ids.length)
          context.commit('ADD_SUBSCRIBERS', response.data.subscribers_ids)
          resolve(response)
        })
        .catch(error => {
          context.dispatch('pageIsLoading', false, {
            root: true
          })
          reject(error)
        })
    })
  },



}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}