var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.siteSettings &&
    _vm.siteSettings.left_discount &&
    _vm.siteSettings.left_discount.length > 0
  )?_c('div',{staticClass:"left-discounts"},[_c('v-divider'),_c('router-link',{attrs:{"to":{
      name: 'CategoryShow',
      params: { slug: 'all' },
      query: { sort: 'discount' },
    }}},[_c('div',{staticClass:"discounts-title"},[_vm._v("Скидки сегодня")]),_c('v-row',{staticClass:"mx-0 justify-center"},_vm._l((_vm.siteSettings.left_discount),function(book,index){return _c('v-col',{key:index,staticClass:"col-4 px-1",attrs:{"index":index}},[_c('img',{attrs:{"src":book.image_small}})])}),1)],1),_c('v-divider')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }