<template>
  <v-list dense>
    <v-subheader inset> Кабинет </v-subheader>
    <v-list-item-group color="primary">
      <!-- <v-list-item :to="{name: 'Home'}" class="mt-1" link>
        <v-list-item-icon>
          <v-icon color="grey darken-1">$vuetify.icons.home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Вернуться на сайт</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <!-- <v-list-item
        v-if="currentUser"
        :to="{name: 'CabinetBalance'}"
        class="mt-1"
        link
      >
        <v-list-item-icon>
          <v-icon color="grey darken-1">$vuetify.icons.wallet</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Баланс: {{ currentUser.balance ? currentUser.balance : 0 }} р.</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <!-- <v-list-item
        v-if="isAuthor"
        :to="{ name: 'CabinetStatisticsDay' }"
        class="mt-1"
        link
      >
        <v-list-item-icon>
          <v-icon color="grey darken-1">$vuetify.icons.wallet</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            >Заработано:
            {{ currentUser.income ? currentUser.income : 0 }}
            р.</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item> -->

      <v-list-item :to="{ name: 'CabinetSettings' }" class="mt-1" link>
        <v-list-item-icon>
          <v-icon color="grey darken-1">$vuetify.icons.account_settings</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Настройки профиля</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="isAuthor"
        :to="{ name: 'CabinetSettingsAuthor' }"
        class="mt-1"
        link
      >
        <v-list-item-icon>
          <v-icon color="grey darken-1">$vuetify.icons.account_settings</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Анкета автора</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isAuthor" :to="{ name: 'CabinetBooks' }" class="mt-1" link>
        <v-list-item-icon>
          <v-icon color="grey darken-1"
            >$vuetify.icons.book_open_page_variant</v-icon
          >
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Произведения</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isAuthor" :to="{ name: 'CabinetSeries' }" class="mt-1" link>
        <v-list-item-icon>
          <v-icon color="grey darken-1">$vuetify.icons.bookshelf</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Циклы произведений</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isAuthor" :to="{ name: 'CabinetBlogs' }" class="mt-1" link>
        <v-list-item-icon>
          <v-icon color="grey darken-1"
            >$vuetify.icons.book_open_page_variant</v-icon
          >
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Блоги</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>

    <v-subheader v-if="isAuthor" inset class="mt-4">Коммерческие данные</v-subheader>
    <v-list-item-group color="primary">
      <v-list-item
        v-if="isAuthor"
        :to="{ name: 'CommerceDocuments' }"
        class="mt-1"
        link
      >
        <v-list-item-icon>
          <v-icon color="grey darken-1">$vuetify.icons.file_document</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Договора</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      

      <!-- <v-list-item :to="{ name: 'CabinetTransactions' }" class="mt-1" link>
        <v-list-item-icon>
          <v-icon color="grey darken-1">$vuetify.icons.wallet_plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Транзакции</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item
        v-if="isAuthor"
        :to="{ name: 'CabinetStatisticsDay' }"
        class="mt-1"
        link
      >
        <v-list-item-icon>
          <v-icon color="grey darken-1">$vuetify.icons.poll</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Статистика продаж</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="isAuthor"
        :to="{ name: 'Page', params: { slug: 'prodavat-knigi' } }"
        class="mt-1"
        link
      >
        <v-list-item-icon>
          <v-icon color="grey darken-1">$vuetify.icons.cart</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Помощь, FAQ</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item
        v-if="isAuthor"
        :to="{ name: 'CabinetStatisticsPayouts' }"
        class="mt-1"
        link
      >
        <v-list-item-icon>
          <v-icon color="grey darken-1">$vuetify.icons.table_clock</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>История выплат</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
    </v-list-item-group>
    <v-subheader v-if="isAuthor" inset class="mt-4">Статистика прочтений</v-subheader>
    <v-list-item-group color="primary">
      <v-list-item
        v-if="isAuthor"
        :to="{ name: 'CabinetStatisticsViews' }"
        class="mt-1"
        link
      >
        <v-list-item-icon>
          <v-icon color="grey darken-1">$vuetify.icons.poll</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Просмотры</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
    }),
    isAuthor() {
      if (this.currentUser && this.currentUser.author) {
        return true;
      }
      return false;
    }
  },
};
</script>
